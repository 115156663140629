import moment from 'moment';

export const dateFormatted = (date, format = 'dddd D MMMM YYYY', locale) => {
  if (!locale) {
    return moment(date).format(format);
  }

  return moment(date).locale(locale).format(format);
};

export const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow;
};

export const getISODate = (date) => moment(date).format('YYYY-MM-DD');

export const getDate = (date) => moment(date).format('DD/MM/YYYY');

export const dateIsAfter = (a, b, type = 'day') => moment(a).isSameOrAfter(b, type);

export const dateIsSame = (a, b) => moment(a).isSame(b, 'day');

export const dateIsBefore = (a, b) => moment(a).isBefore(b, 'day');

/**
 *
 * @param {ISOstring} date
 * @returns {Date}
 */
export const getFirstDayofMonth = (date) => moment(date).startOf('month').toDate();

/**
 *
 * @param {Date} date
 * @param {String} time, a string like 17:00:00
 */
export const getSypnosisDate = (date, time) => {
  try {
    return moment(date)
      .format('dd,ll')
      .split(',')
      .map((seg, idx) => {
        if (idx === 0) {
          seg = seg + ',';
        }

        return seg;
      })
      .slice(0, 2)
      .concat(time.slice(0, 5))
      .join(' ');
  } catch (error) {
    return '';
  }
};

export const isXMinutesBefore = (moment, time, date, minsOffset) => {
  const format = 'YYYY-MM-DD, H:mm:ss';
  const now = moment();
  const scheduledTime = moment(`${date},${time}`, format);
  const XMinutesBefore = moment(`${date},${time}`, format).subtract(minsOffset, 'minutes');

  return now.isBetween(XMinutesBefore.toDate(), scheduledTime.toDate());
};

export const isXMinutesAfter = (moment, time, date, minsOffset) => {
  const format = 'YYYY-MM-DD, H:mm:ss';
  const now = moment();
  const scheduledTime = moment(`${date},${time}`, format);
  const XMinutesAfter = moment(`${date}, ${time}`, format).add(minsOffset, 'minutes');

  return now.isBetween(scheduledTime.toDate(), XMinutesAfter.toDate());
};

export const getHoursByInterval = (hours, interval = 30) => {
  const [h, m, s] = hours.split(':');
  const start = moment().set({h, m, s, ms: 0});
  const end = moment(start).add(interval, 'm');
  return `${start.format('HH:mm')} - ${end.format('HH:mm')}`;
};
