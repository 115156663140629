import React from 'react';
import {Route, Redirect, Switch, useRouteMatch} from 'react-router-dom';
import Customer from 'components/Customer';
import AppointmentConfirmed from 'components/Customer/AppointmentConfirmed';
import AppointmentCancellation from 'components/Customer/AppointmentCancellation';

const Appointment = () => {
  const {url} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/success`}>
        <AppointmentConfirmed />
      </Route>
      <Route path={`${url}/cancel`}>
        <AppointmentCancellation />
      </Route>
      <Route path={`${url}/populate`}>
        <Customer />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default Appointment;
