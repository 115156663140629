import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Synopsis from '../Synopsis';

const {assetDirecory} = require('utils/brand');

const Sector = ({label, disabled, state, sectorSynopsis, sectorExpanded, toggleSector}) => (
  <Fragment key={label}>
    {state.selected ? (
      <Card className="synopsis-card rounded-meniscus mb-2">
        {disabled && (
          <div className="overlay disabled rounded-meniscus position-absolute w-100 h-100" />
        )}
        <Card.Body className="p-2 p-md-3">
          <Synopsis title={label} selected onToggleSector={toggleSector}>
            {sectorSynopsis}
          </Synopsis>
        </Card.Body>
      </Card>
    ) : (
      <Button
        disabled={disabled}
        key={label}
        variant="sector"
        className="rounded-meniscus fs-18 fs-md-22 text-secondary w-100 mb-2 px-2 px-md-3 py-2 d-flex justify-content-between align-items-center"
        onClick={toggleSector}
      >
        <span>{label}</span>
        <img
          src={require(`assets/images/${assetDirecory}/icons/arrow-down.svg`)}
          className="wi-16 wi-md-25"
          alt="select sector"
        />
      </Button>
    )}
    {state.editable && (
      <Card className="expanded rounded-meniscus position-absolute shadow w-100">
        <Card.Body className="py-3 px-2 px-md-4">{sectorExpanded}</Card.Body>
      </Card>
    )}
  </Fragment>
);

Sector.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  sectorSynopsis: PropTypes.node.isRequired,
  sectorExpanded: PropTypes.node.isRequired,
  toggleSector: PropTypes.func.isRequired
};

export default memo(Sector);
