import React from 'react';

import {Badge, BadgeContainer} from './Badge';

function NotificationBadge({unit = 15, children, onClick, className}) {
  const displayUnit = unit > 10 ? '9+' : unit;

  return (
    <Badge onClick={onClick} className={className}>
      <span className="badge-cta">{children}</span>
      <sup className="badge-number" title={unit}>
        <span className="badge-number-only">
          <p className="badge-number-only-unit">{displayUnit}</p>
        </span>
      </sup>
    </Badge>
  );
}

export {NotificationBadge, BadgeContainer};
