import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

const onChange = (el, restriction, customId, selected, onChangeService) =>
  restriction && !selected ? undefined : onChangeService(el, customId, !selected, 2);

const ServiceBox = ({
  restriction,
  customId,
  description,
  url,
  info,
  selected,
  onChangeService,
  t
}) => (
  <Fragment>
    <Card.Body className="d-flex align-items-center justify-content-sm-center justify-content-between p-0">
      <div
        className="py-4 px-2"
        onClick={(el) => onChange(el, restriction, customId, selected, onChangeService)}
      >
        <span className="font-weight-bold">{description}</span>
        {info && <div className="fs-12">{info}</div>}
        {selected && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="d-block fs-12 text-white preventClick"
          >
            {t('MORE')}
          </a>
        )}
      </div>
      <Form.Check
        id={`checkbox-${customId}`}
        value={customId}
        label=""
        className="duality box-checkbox"
        type="checkbox"
        checked={selected}
        onChange={(el) => onChange(el, restriction, customId, selected, onChangeService)}
      />
    </Card.Body>
  </Fragment>
);

ServiceBox.defaultProps = {
  info: ''
};

ServiceBox.propTypes = {
  restriction: PropTypes.bool.isRequired,
  customId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  info: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(ServiceBox);
