import {useLayoutEffect, useCallback, useRef} from 'react';

/**
 * Is used to memoize non original or indirect react dispatch method in order to use it safely in useEffect dependencies array
 * @param {dispatch function} dispatch
 */
function useSafeDispatch(dispatch) {
  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  return useCallback((...args) => (mounted.current ? dispatch(...args) : void 0), [dispatch]);
}

export default useSafeDispatch;
