import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classnames from 'classnames';
import {Redirect, useLocation} from 'react-router-dom';

import ResetPasswordForm from 'components/Agent/Login/ResetPasswordForm';
import UpdatePasswordForm from 'components/Agent/Login/UpdatePasswordForm';
import {useAuth} from 'context/agent/auth-context';
import {isMobile, isHDTablet} from 'utils/size';
import {loginNextRoute} from 'utils/routes';
import LoginForm from './LoginForm';
import useWindowSize from 'hooks/useWindowSize';
import GradientGrid from 'components/common/GradientGrid';
import {brandUpperCase, isCosmote} from 'utils/brand';
const {assetDirecory} = require('utils/brand');

const LoginScreen = () => {
  const auth = useAuth();
  const {t} = useTranslation();
  const {width} = useWindowSize();
  const [passwordResetVisible, setPasswordResetVisible] = useState(false);
  const urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get('token');
  const ctaLabel = urlParams.get('cta');

  const jumbotronClasses = classnames('font-weight-bold text-white login-layout__jumbotron', {
    'fs-48': !isMobile(width),
    'fs-30': isMobile(width)
  });

  return (
    <>
      {auth.isAuthenticated() && !token ? (
        <Redirect to={loginNextRoute(auth.isAdmin)} />
      ) : (
        <div className="login-layout main-section main-section--agent d-flex align-items-center pt-5 pb-5">
          <GradientGrid
            image={require(`assets/images/${assetDirecory}/desktop.png`)}
            xs={12}
            imageStyles={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              maxWidth: isHDTablet(width) ? '100%' : '55%',
              transform: 'translate(-50%, -50%)'
            }}
          />
          <div className="bg-cover bg-screen"></div>
          <Container className="login-layout__container">
            <Row>
              <Col className="d-flex flex-column justify-content-center mb-5 login-layout__left">
                <div className={jumbotronClasses}>
                  {isCosmote ? (
                    <span>
                      {brandUpperCase} <br /> Book Appointment App
                    </span>
                  ) : (
                    'G click to visit'
                  )}
                </div>
              </Col>
              <Col xl="4" lg="5" className="d-flex justify-content-center">
                {!passwordResetVisible && !token && (
                  <LoginForm
                    onResetPress={() => setPasswordResetVisible(true)}
                    isMobile={isMobile(width)}
                    t={t}
                  />
                )}
                {passwordResetVisible && !token && (
                  <ResetPasswordForm t={t} onCancel={() => setPasswordResetVisible(false)} />
                )}
                {token && <UpdatePasswordForm t={t} token={token} cta={ctaLabel} />}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default LoginScreen;
