import isEmpty from 'lodash.isempty';

const isEmailAddress = (str) => {
  const algorithm = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return algorithm.test(str);
};

export const isPersonName = (str) => {
  const name = /^[a-zA-Zά-ωΑ-ώ]+(([',. -][a-zA-Zά-ωΑ-ώ ])?[a-zA-Zά-ωΑ-ώ]*)*$/g;

  return name.test(str);
};

export const isFullName = (str) => {
  if (str == null) return false;

  const fullname = /^[a-zA-Zά-ωΑ-ώ]+(([',. -][a-zA-Zά-ωΑ-ώ ])?[a-zA-Zά-ωΑ-ώ]*)*$/g;

  return fullname.test(str);
};

const isNumber = (str) => /^\d+$/.test(str);

const isDate = (value) => {
  if (isNaN(Date.parse(value))) {
    return false;
  }

  return true;
};

export const validateCustomerInfo = (
  firstname,
  lastname,
  mobile,
  email,
  referencePhoneNumber,
  t
) => {
  const errors = {};
  const mandatoryFieldTr = t('MANDATORY_FIELD');

  if (isEmpty(firstname)) {
    errors['firstname'] = mandatoryFieldTr;
  } else {
    if (!isPersonName(firstname)) {
      errors['firstname'] = t('INVALID_FIRSTNAME');
    }
  }

  if (isEmpty(lastname)) {
    errors['lastname'] = mandatoryFieldTr;
  } else {
    if (!isPersonName(lastname)) {
      errors['lastname'] = t('INVALID_LASTNAME');
    }
  }

  if (isEmpty(mobile)) {
    errors['mobile'] = mandatoryFieldTr;
  } else {
    if (!/^69[0-9]{8}$/.test(mobile)) {
      errors['mobile'] = t('INVALID_MOBILE_NUMBER');
    }
  }

  if (!isEmpty(email) && !isEmailAddress(email)) {
    errors['email'] = t('INVALID_EMAIL');
  }

  if (!isEmpty(referencePhoneNumber)) {
    if (referencePhoneNumber.length !== 10 || !isNumber(referencePhoneNumber))
      errors['referencePhoneNumber'] = t('INVALID_NUMBER');
  }

  return {
    formIsValid: Object.keys(errors).length < 1,
    errors
  };
};

export const validateService = (service) =>
  !isEmpty(service.sectorValue) && !isEmpty(service.servicesValue);

export const validateDatetime = (datetime) =>
  isDate(datetime.dateValue) && !isEmpty(datetime.timeValue);
