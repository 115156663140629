import styled from '@emotion/styled';

const Badge = styled('span')`
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  line-height: 1;

  .badge-cta {
    width: 44px;
    height: 44px;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      background: #eaebec;
    }

    .fa {
      font-size: 22px !important;
    }
  }

  .badge-number {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    min-width: 15px;
    height: 15px;
    padding: 1px 3px;
    color: #fff;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
    cursor: pointer;
  }

  .badge-number-only {
    height: 15px;
    position: relative;
    display: inline-block;
  }

  .badge-number-only-unit {
    margin: 0;
    height: 15px;
  }
`;

const BadgeContainer = styled('div')`
  max-width: 178px;
  text-align: right;
  line-height: 18px;
`;

export {Badge, BadgeContainer};
