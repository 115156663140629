import axios from 'axios';
import {apiKey} from 'utils/google';

/**
 * Gets geolocation coordinates via Browser Geolocation Api
 * @param {Promise}
 */
export const getGeoCoords = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

/**
 * Uses google api to fet geocode via a given location object
 * @param {Object} coords, location objext
 * @param {String} language
 * @returns {Object|Error}
 */
export async function getGeoCode(coords, language) {
  const {latitude, longitude} = coords;

  try {
    const {data} = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${language}&key=${apiKey}`
    );

    return data;
  } catch (error) {
    throw new Error(`Apologies, we couldn't justify your address details`);
  }
}
