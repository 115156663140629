import React from 'react';
import {Button, Form, Card} from 'react-bootstrap';
import classnames from 'classnames';

import Loader from 'components/common/Loader';
import useForm from 'hooks/useForm';
import {useAsync} from 'hooks/useAsync';
import {resetPassword} from 'api/agent';
import resetPasswordSchema from 'validations/resetPasswordSchema';
import {errors as apiErrors} from 'utils/error';

function ResetPasswordForm({isMobile, t, onCancel}) {
  const {run, isLoading, error, isSuccess, setIdle} = useAsync();
  const {values, handleChange, handleSubmit, handleBlur, valid, touched, errors} = useForm({
    initialValues: {
      email: ''
    },
    onSubmit: () => run(resetPassword({email: values.email})),
    validationSchema: resetPasswordSchema
  });
  const onCloseSuccess = () => setIdle();

  const cardClasses = classnames('bg-white', {
    'p-20': isMobile,
    'p-30': !isMobile
  });
  const loaderIconDimension = isMobile ? 40 : 80;

  return (
    <>
      <Form className="login-form" onSubmit={handleSubmit}>
        {isSuccess && (
          <Card className="mb-4">
            <Card.Body
              className={[cardClasses, 'd-flex justify-content-between align-items-center'].join(
                ' '
              )}
            >
              <div>
                <i className="fas fa-check-circle text-primary mr-2"></i>
                <strong>{t('CHECK_EMAIL')}</strong>
              </div>
              <button className="close-button" onClick={onCloseSuccess}>
                <i className="fa fa-times-circle"></i>
              </button>
            </Card.Body>
          </Card>
        )}
        <Card>
          {isLoading && (
            <Loader
              wrapperClasses="my-5 fs-20 fs-md-26"
              width={loaderIconDimension}
              height={loaderIconDimension}
            />
          )}
          {!isLoading && (
            <>
              <Card.Body className={cardClasses}>
                <h3 className="fs-18 font-weight-bold">{t('RESET_PASSWORD')}</h3>
                {error && (
                  <div className="login-form-error fs-16 mt-5">
                    {t(`ERRORS:${apiErrors[error?.code] ?? 'GENERAL_ERROR'}`)}
                  </div>
                )}
                <Form.Group controlId="formBasicEmail" className="mt-5">
                  <Form.Label className="mb-3">{t('EMAIL')}</Form.Label>
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder={t('LOGIN_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={!!errors.email && touched.email}
                  />

                  {touched.email && (
                    <Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Card.Body>
              <Card.Footer className={cardClasses}>
                <button className="btn-cancel font-weight-bold fs-14" onClick={onCancel}>
                  {t('CANCEL')}
                </button>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-3d w-48 fs-14 float-right"
                  disabled={!valid}
                >
                  {t('EMAIL_SENT')}
                </Button>
              </Card.Footer>
            </>
          )}
        </Card>
      </Form>
    </>
  );
}

export default ResetPasswordForm;
