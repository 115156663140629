import React, {memo} from 'react';
import PropTypes from 'prop-types';

const CustomerInfoSynopsis = ({
  firstnameValue,
  lastnameValue,
  mobileValue,
  emailValue,
  referencePhoneNumberValue,
  onClick,
  t
}) => (
  <div className="font-weight-semibold">
    {(firstnameValue || lastnameValue) && (
      <div className="text-truncate">
        {`${firstnameValue} ${lastnameValue}`}
        {onClick && (
          <button type="button" className="link-button ml-1" onClick={onClick}>
            {t('CHANGE')}
          </button>
        )}
      </div>
    )}
    {mobileValue && <div className="text-truncate">{mobileValue}</div>}
    {emailValue && <div className="text-truncate">{emailValue}</div>}
    {referencePhoneNumberValue && <div className="text-truncate">{referencePhoneNumberValue}</div>}
  </div>
);

CustomerInfoSynopsis.propTypes = {
  firstnameValue: PropTypes.string.isRequired,
  lastnameValue: PropTypes.string.isRequired,
  mobileValue: PropTypes.string.isRequired,
  emailValue: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default memo(CustomerInfoSynopsis);
