import React from 'react';
import {Route} from 'react-router-dom';
import Agent from 'components/Agent';
import Admin from 'components/Admin';
import Reporter from 'components/Reporter';
import {AuthProvider} from 'context/agent/auth-context';

const Authentication = () => {
  return (
    <AuthProvider>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/agent">
        <Agent />
      </Route>
      <Route path="/reports">
        <Reporter />
      </Route>
    </AuthProvider>
  );
};

export default Authentication;
