import React, {Fragment, memo} from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const MainLabel = styled.div`
  line-height: 43px;
`;

const LeftSide = ({t, text}) => (
  <Fragment>
    <MainLabel className="fs-48 font-weight-bold mb-4">{t(text.label)}</MainLabel>
    <h4 className="mb-4">{t(text.description)}</h4>
  </Fragment>
);

LeftSide.propTypes = {
  t: PropTypes.func.isRequired
};

export default memo(LeftSide);
