import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {fetchAppointmentId} from 'api/customer';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {showOTP} from 'context/customer/actions';

const usePopulateAppointment = (otp, appointmentId, xUpdateToken, language) => {
  const [populateLoading, setPopulateLoading] = useState(false);
  const [populateError, setPopulateError] = useState(false);
  const dispatch = useCustomerDispatch();
  const urlParams = new URLSearchParams(decodeURIComponent(useLocation().search));
  const token = urlParams.get('token');

  useEffect(() => {
    async function fetch() {
      try {
        setPopulateLoading(true);
        const {appointmentId} = await fetchAppointmentId({token, lang: language});
        showOTP({dispatch}, {appointmentId, appointmentMode: 'edit'});
      } catch (error) {
        setPopulateError(error?.code ?? true);
      } finally {
        setPopulateLoading(false);
      }
    }

    if (!otp.success && token) {
      fetch();
    }
  }, [token, otp.success, language, dispatch]);

  return [populateLoading, populateError, token, setPopulateError];
};

export default usePopulateAppointment;
