import React, {Fragment, lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {isCosmote} from 'utils/brand';

const CosmoteTheme = lazy(() => import('./Cosmote'));
const GermanosTheme = lazy(() => import('./Germanos'));

const ThemeSelector = ({children}) => (
  <Fragment>
    <Suspense fallback={<Fragment />}>
      {isCosmote ? <CosmoteTheme /> : <GermanosTheme />}
      {children}
    </Suspense>
  </Fragment>
);

ThemeSelector.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeSelector;
