import React, {createContext, useContext, useReducer, useMemo} from 'react';
import {PURCHASE_REQUEST, DIGITAL_CARE} from 'components/Customer/Sector/ServiceSector/helper';
import {reducer, initialState} from './customerReducer';

const CustomerContext = createContext();
const CustomerDispatchContext = createContext();

const CustomerProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedState = useMemo(() => state, [state]);
  const memoizedDispatch = useMemo(() => dispatch, [dispatch]);

  return (
    <CustomerContext.Provider value={memoizedState}>
      <CustomerDispatchContext.Provider value={memoizedDispatch}>
        {children}
      </CustomerDispatchContext.Provider>
    </CustomerContext.Provider>
  );
};

function useCustomerState() {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomerState must be used within a CustomerProvider');
  }

  const {appointmentType} = context;
  const isPurchase = [DIGITAL_CARE, PURCHASE_REQUEST].includes(appointmentType);

  return {isPurchase, ...context};
}

function useCustomerDispatch() {
  const context = useContext(CustomerDispatchContext);
  if (context === undefined) {
    throw new Error('useCustomerDispatch must be used within a CustomerProvider');
  }

  return context;
}

export {CustomerContext, CustomerProvider, useCustomerState, useCustomerDispatch};
