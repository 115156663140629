import React, {Fragment, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {RESIDENTIAL} from 'components/Customer/Sector/ServiceSector/helper';
import {useCustomerState} from 'context/customer/customer-context';
import useWindowSize from 'hooks/useWindowSize';
import Header from './Header';
import Section from './Section';
import LeftSide from './Section/LeftSide';
import SectorsForm from './SectorsForm';
import FrontTablet from './FrontTablet';
import {isMobile, isTablet} from 'utils/size';
import {SECTORS_DETAILS} from 'utils/brand';

const Customer = () => {
  const {serviceSector} = useCustomerState();
  const {width} = useWindowSize();
  const {t, i18n} = useTranslation();
  const urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get('token');
  const {language} = i18n;

  const [frontTablet, setFrontTablet] = useState({
    display: !isTablet(width),
    btnClicked: false
  });

  useEffect(() => {
    if (!frontTablet.btnClicked) {
      setFrontTablet((prevState) => ({
        ...prevState,
        display: isTablet(width) && !token
      }));
    }
  }, [width, token, frontTablet.btnClicked]);

  const sector = serviceSector.sectorValue?.code ?? RESIDENTIAL;

  return (
    <Fragment>
      <Header i18n={i18n} />
      {frontTablet.display ? (
        <FrontTablet t={t} onClick={setFrontTablet} />
      ) : (
        <Section
          isTablet={isTablet(width)}
          leftSide={<LeftSide text={SECTORS_DETAILS[sector].text} t={t} />}
          language={language}
          t={t}
          image={SECTORS_DETAILS[sector].image}
        >
          <SectorsForm isMobile={isMobile(width)} language={language} t={t} />
        </Section>
      )}
    </Fragment>
  );
};

export default Customer;
