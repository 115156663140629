import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleCustomerInfo} from 'context/customer/actions';
import Sector from './';
import CustomerInfo from 'components/Customer/Sector/CustomerInfo';
import CustomerInfoSynopsis from 'components/Customer/Synopsis/CustomerInfoSynopsis';

const CustomerInfoCard = () => {
  const {t} = useTranslation();
  const {store, datetime, customerInfo, isPurchase} = useCustomerState();
  const dispatch = useCustomerDispatch();

  return (
    <Sector
      label={t('CUSTOMER_INFORMATION')}
      disabled={isPurchase ? !store.selected : !datetime.selected}
      state={customerInfo}
      toggleSector={() => toggleCustomerInfo({dispatch})}
      sectorExpanded={<CustomerInfo />}
      sectorSynopsis={
        <CustomerInfoSynopsis
          {...customerInfo}
          onClick={() => toggleCustomerInfo({dispatch})}
          t={t}
        />
      }
    />
  );
};

export default memo(CustomerInfoCard);
