import {setupWorker, rest} from 'msw';

import {login} from 'mocks/api/user';
import {getAppointmentById, stopAppointment} from 'mocks/api/agent';
import {getServicesList} from 'mocks/api/customer';

import {getStores} from 'mocks/api/admin';

const mockApiWorker = setupWorker(
  rest.post('http://localhost:3000/agent/api/v1/user/login', login),
  rest.get('http://localhost:3000/agent/api/v1/appointment/sdfsdfsdf', getAppointmentById),
  rest.post('appointment/5f6c410512df0845c8e5f84b/stop', stopAppointment),
  rest.get('http://localhost:3000/booking/api/v1/service/:customerType/*', getServicesList),
  // admin routes
  rest.get('/getStores', getStores)
);

export {mockApiWorker};
