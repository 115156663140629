import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';
import {en, el} from 'locales';
import {useLanguage} from 'context/agent/language-context';

function Lang({type = 'el'}) {
  return (
    <img src={require(`assets/images/common/icons/ic-flag-${type}.svg`)} alt={`language ${type}`} />
  );
}

Lang.propTypes = {
  image: PropTypes.string,
  type: PropTypes.string
};

function LangSwitcher({isAuthenticated}) {
  const {updateLanguage} = useLanguage();
  const {i18n} = useTranslation();

  const dropdownClasses = classnames({
    'mr-2': isAuthenticated
  });

  const onChangeLanguage = (locale) => updateLanguage(locale);

  return (
    <Dropdown className={dropdownClasses} bsPrefix="lang-switcher">
      <Dropdown.Toggle variant="none" id="language-switcher">
        <Lang type={i18n.language} />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        <Dropdown.Item onClick={() => onChangeLanguage(en)}>
          <Lang type={en} />
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onChangeLanguage(el)}>
          <Lang type={el} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

LangSwitcher.defaultProps = {
  isAuthenticated: false
};

LangSwitcher.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

export default LangSwitcher;
