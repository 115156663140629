import moment from 'moment';
import {RESIDENTIAL} from 'components/Customer/Sector/ServiceSector/helper';

const timeUnits = {
  0: 'hours',
  1: 'minutes',
  2: 'seconds'
};

const getCalendarDates = (date, type) => {
  if (date?.dateValue && date?.timeValue && type) {
    const isResidential = type === RESIDENTIAL;
    const timeObj = date?.timeValue.split(':').reduce((acc, item, index) => {
      acc[timeUnits[index]] = item;
      return acc;
    }, {});
    const start = moment(date?.dateValue).add(timeObj);

    return {
      start,
      end: moment(start).add(isResidential ? 30 : 1, isResidential ? 'minutes' : 'hours')
    };
  }
  return null;
};

const icsDateFormat = (date) => moment(date).format('YYYYMMDDTHHmmss');
const downloadICSFile = (title, description, startDate, endDate) => {
  const url = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `DTSTART:${icsDateFormat(startDate)}`,
    `DTEND:${icsDateFormat(endDate)}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\n');

  return encodeURI(`data:text/calendar;charset=utf8,${url}`);
};

export {downloadICSFile, getCalendarDates};
