import React from 'react';
import PropTypes from 'prop-types';
import {Form, Col} from 'react-bootstrap';
import styled from '@emotion/styled';

const Column = styled(Col)`
  position: absolute;
`;

const GradientGrid = ({imageStyles, image, xs}) => (
  <Column xs={xs} className="gradient-grid gradient-grid--v2 h-100 overflow-hidden pl-0 pr-0 mt-0">
    <Form.Row className="h-100">
      {image && <img src={image} alt="cosmote appointment" style={{...imageStyles}} />}
    </Form.Row>
  </Column>
);

GradientGrid.defaultProps = {
  xs: 7
};

GradientGrid.propTypes = {
  image: PropTypes.node,
  imageStyles: PropTypes.object,
  xs: PropTypes.number
};

export default GradientGrid;
