import useCreateUpdateAppointment from 'hooks/useCreateUpdateAppointment';
import usePopulateAppointment from 'hooks/usePopulateAppointment';
import usePickupDetails from 'hooks/usePickupDetails';
import useServicePickupDetails from 'hooks/useServicePickupDetails';
import {useCustomerState} from 'context/customer/customer-context';

function useSectorForm(language, t) {
  const {
    appointmentSuccess,
    otp,
    appointmentMode,
    appointmentType,
    isPurchase,
    appointmentId,
    xUpdateToken,
    serviceSector,
    store,
    datetime,
    customerInfo,
    orderId,
    pickupServiceId,
    hashed
  } = useCustomerState();

  const [onSubmitBtn, submitLoading, submitError, setSubmitError] = useCreateUpdateAppointment(
    serviceSector,
    store,
    datetime,
    customerInfo,
    orderId,
    pickupServiceId,
    hashed,
    xUpdateToken,
    appointmentMode,
    appointmentType,
    language,
    appointmentId,
    isPurchase
  );
  const [populateLoading, populateError, token, setPopulateError] = usePopulateAppointment(
    otp,
    appointmentId,
    xUpdateToken,
    language,
    t
  );
  const [pickUpLoading, pickUpError, setPickUpError] = usePickupDetails(appointmentId);
  const [
    servicePickUpLoading,
    servicePickUpError,
    setServicePickUpError
  ] = useServicePickupDetails();

  const handleBackOnError = () => {
    setPopulateError(false);
    setSubmitError(false);
    setPickUpError(false);
    setServicePickUpError(false);
  };

  const editable =
    serviceSector.editable || store.editable || datetime.editable || customerInfo.editable;
  const btnEnabled =
    serviceSector.selected &&
    store.selected &&
    (isPurchase ? true : datetime.selected) &&
    customerInfo.selected;

  return {
    appointmentSuccess,
    appointmentMode,
    appointmentId,
    btnEnabled,
    editable,
    otp,
    isPurchase,
    isLoading: submitLoading || populateLoading || pickUpLoading || servicePickUpLoading,
    error: submitError || populateError || pickUpError || servicePickUpError,
    token,
    onSubmitBtn,
    handleBackOnError,
    t
  };
}

export default useSectorForm;
