/* eslint-disable no-restricted-globals */
if (typeof window != 'object') {
  self.window = self;
}

const {hostname} = self.location;

const isCosmote = (() => {
  if (process.env.REACT_APP_BRAND === 'cosmote') {
    return true;
  }
  return hostname.includes('cosmote');
})();

const assetDirecory = isCosmote ? 'cosmote' : 'germanos';

const domain = isCosmote ? 'https://www.cosmote.gr/' : 'https://www.germanos.gr/';

const brandParam = isCosmote ? 'cosmote' : 'germanos';

const brandUpperCase = isCosmote ? 'COSMOTE' : 'GERMANOS';

const SECTORS_DETAILS = {
  residential: {
    text: {
      label: `MAIN_LABEL_${brandUpperCase}_RESIDENTIAL`,
      description: `MAIN_DESCRIPTION_${brandUpperCase}_RESIDENTIAL`
    },
    image: 'customer'
  },
  business: {
    text: {
      label: `MAIN_LABEL_${brandUpperCase}_BUSINESS`,
      description: `MAIN_DESCRIPTION_${brandUpperCase}_BUSINESS`
    },
    image: 'business'
  }
};

const displayFavicon = () => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = require(`assets/images/${assetDirecory}/favicon.ico`);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export {
  isCosmote,
  assetDirecory,
  domain,
  brandParam,
  displayFavicon,
  brandUpperCase,
  SECTORS_DETAILS
};
