import React, {Fragment, memo, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import CardFlowWrapper from '../CardFlowWrapper';
import ErrorMessage from 'components/common/ErrorMessage';
import Loader from 'components/common/Loader';
import Otp from 'components/Customer/Otp';
import {cancelAppointment} from 'api/customer';
import usePopulateAppointment from 'hooks/usePopulateAppointment';
import {useCustomerState} from 'context/customer/customer-context';

const CardCancellation = ({isMobile, language, t}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const {otp, xUpdateToken, appointmentId} = useCustomerState();
  const [populateLoading, populateError, token] = usePopulateAppointment(
    otp,
    xUpdateToken,
    appointmentId,
    language,
    t
  );
  const iconDimension = isMobile ? 40 : 80;

  const onSubmitBtn = async () => {
    try {
      setLoading(true);
      setError(false);
      await cancelAppointment({
        data: {appointmentId},
        headers: {'X-UPDATE-TOKEN': xUpdateToken},
        lang: language
      });
      setConfirm(true);
    } catch (error) {
      setError({show: true, error: error.code});
    } finally {
      setLoading(false);
    }
  };

  if (otp.show) {
    return <Otp />;
  }

  return (
    <Fragment>
      <CardFlowWrapper bodyClasses="py-3 px-3 px-md-5 text-center font-weight-semibold">
        {(loading || populateLoading) && (
          <Loader
            wrapperClasses="my-5 fs-20 fs-md-26"
            width={iconDimension}
            height={iconDimension}
          />
        )}
        {(error || populateError) && (
          <ErrorMessage
            wrapperClasses="my-5 fs-20 fs-md-26"
            width={iconDimension}
            code={error || populateError}
          />
        )}
        {confirm && <div className="my-5 fs-20 fs-md-26">{t('APPOINTMENT_DELETED')}</div>}
        {!(loading || error || populateError || confirm) && (
          <Fragment>
            <div className="my-5">
              <div className="mb-3 fs-20 fs-md-26">{t('SURE_CANCEL_APPOINTMENT')}</div>
            </div>
            <Button
              variant="primary"
              className="rounded-pill btn-cover px-4 mt-2 font-weight-semibold"
              onClick={onSubmitBtn}
            >
              {t('CANCELLATION_CONFIRMATION')}
            </Button>
            <Link
              to={`/appointment/populate${token ? `?token=${token}` : ''}`}
              className="mt-3 cursor-pointer text-secondary d-block text-decoration-none"
            >
              {t('BACK_VISIT_DETAILS')}
            </Link>
          </Fragment>
        )}
      </CardFlowWrapper>
    </Fragment>
  );
};

CardCancellation.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(CardCancellation);
