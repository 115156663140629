import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

import useWindowSize from 'hooks/useWindowSize';
import {isMobile} from 'utils/size';
import {isCosmote} from 'utils/brand';
import SidebarMenu from './SidebarMenu';
import LangSwitcher from './LangSwitcher';
import NotificationBox from './NotificationBox';
import CogDropdown from './CogDropdown';
import StoreAppointmentDropdown from './StoreAppointmentDropdown';
import {timelineRoute, dashboardRoute} from 'utils/routes';
import {BadgeContainer, NotificationBadge as Badge} from 'components/common/Badge';
import {useRequestsState, useRequestsDispatch} from 'context/agent/requests/request-context';
import {useAuth} from 'context/agent/auth-context';

const {assetDirecory, domain} = require('utils/brand');

function AgentHeader() {
  const {t} = useTranslation();
  const {requestsCount} = useRequestsState();
  const {toggleDrawer} = useRequestsDispatch();
  const {width} = useWindowSize();
  const mobile = isMobile(width);
  const [notification, setNotification] = useState({
    display: false,
    message: 15
  });
  const [displayMenu, setDisplayMenu] = useState(false);
  const {isAuthenticated, authState, logout, isAdmin, isUser, scheduleSettingsAccess} = useAuth();
  const {display, message} = notification;
  const isGermanosUser = isUser && !isCosmote;

  const onCloseNotification = () => {
    setNotification((prevState) => ({
      ...prevState,
      display: false
    }));
  };

  const handleMenu = () => {
    setDisplayMenu((display) => !display);
  };

  const logoutSidebar = () => {
    handleMenu();
    logout();
  };

  const onClickBadge = () => toggleDrawer();

  const dropdownIcon = classnames('icon-header icon-header-store fa mx-2', {
    'fa-store-alt': isUser,
    'fa-user': isAdmin
  });

  return (
    <>
      {mobile && (
        <SidebarMenu
          active={displayMenu}
          isGermanosUser={isGermanosUser}
          handleMenu={handleMenu}
          logout={logoutSidebar}
        />
      )}
      <header className="header header--agent fixed-top bg-white d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          <a href={domain}>
            <img
              src={require(`assets/images/${assetDirecory}/logo.png`)}
              className="logo logo--agent"
              alt="logo"
            />
          </a>
          <div className="d-flex align-items-center">
            {isAuthenticated() ? (
              mobile ? (
                <>
                  {isCosmote && <LangSwitcher isAuthenticated />}
                  {isUser ? (
                    <Badge role="button" unit={requestsCount} onClick={handleMenu}>
                      <i className="icon-header fa fa-bars" />
                    </Badge>
                  ) : (
                    <i className="icon-header fa fa-bars" onClick={handleMenu} />
                  )}
                </>
              ) : (
                <>
                  {isUser && (
                    <NavLink
                      className="text-decoration-none"
                      activeClassName="menu-active"
                      to={timelineRoute()}
                    >
                      <i className="icon-header fa fa-list-ul mr-2" />
                    </NavLink>
                  )}
                  {!isAdmin && (isGermanosUser || scheduleSettingsAccess) && (
                    <StoreAppointmentDropdown t={t} />
                  )}
                  {isAdmin && <CogDropdown t={t} />}
                  {isUser && (
                    <NavLink
                      className="text-decoration-none"
                      activeClassName="menu-active"
                      to={dashboardRoute()}
                    >
                      <i className="icon-header fa fa-chart-pie mr-2" />
                    </NavLink>
                  )}
                  {isUser && (
                    <BadgeContainer
                      onClick={onClickBadge}
                      role="button"
                      className="header-store d-flex align-items-center px-1"
                    >
                      <span>{t('REQUESTS_TO_BUY')}</span>
                      <Badge unit={requestsCount}>
                        <i className="icon-header fa fa-question-circle mr-2" />
                      </Badge>
                    </BadgeContainer>
                  )}
                  {isCosmote && <LangSwitcher isAuthenticated />}
                  <Dropdown bsPrefix="custom-dropdown">
                    <Dropdown.Toggle variant="none" id="menu-store">
                      <div className="header-store d-flex align-items-center px-1 fs-14">
                        <div>
                          {authState?.storename && <div>{authState?.storename}</div>}
                          {authState?.email}
                        </div>
                        <i className={dropdownIcon} />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight>
                      <Dropdown.Item onClick={logout}>{t('LOGOUT')}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )
            ) : (
              isCosmote && <LangSwitcher />
            )}
          </div>
        </div>
        {display && <NotificationBox message={message} onClose={onCloseNotification} />}
      </header>
    </>
  );
}

export default AgentHeader;
