import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import {useHistory} from 'react-router-dom';

import {storeHoursRoute, scheduleSettingsRoute} from 'utils/routes';
import {useAuth} from 'context/agent/auth-context';
import {isCosmote} from 'utils/brand';

const menuOptions = (t, storeId, roles) => [
  {
    label: t('ADMIN:UPDATE_APPOINTMENT_HOURS'),
    key: 'schedule',
    href: scheduleSettingsRoute(storeId),
    display: true
  },
  {
    label: t('ADMIN:UPDATE_STORE_HOURS'),
    key: 'store-hours',
    href: storeHoursRoute(storeId),
    display: !isCosmote && (roles.isOwner || roles.isStoreManager)
  }
];

export {menuOptions};

const StoreAppointmentDropdown = ({t}) => {
  const history = useHistory();
  const {isOwner, isStoreManager, getStoreId} = useAuth();
  const storeId = getStoreId();

  return (
    <Dropdown bsPrefix="custom-dropdown">
      <Dropdown.Toggle variant="none" id="menu-cog">
        <div className="text-center px-1">
          <i className="icon-header fa fa-clock" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        {menuOptions(t, storeId, {isOwner, isStoreManager})
          .filter((opt) => opt.display)
          .map(({key, label, href}) => (
            <Dropdown.Item key={key} onClick={() => history.push(href)}>
              {label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

StoreAppointmentDropdown.propTypes = {
  t: PropTypes.func.isRequired
};

export default StoreAppointmentDropdown;
