import {useState} from 'react';
import {makeAppointment, updateAppointment, makePurchase} from 'api/customer';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {showOTP, successAppointment} from 'context/customer/actions';
import {servicesIdsSelected, PICKUP} from 'components/Customer/Sector/ServiceSector/helper';
import {getISODate} from 'utils/time';

const useCreateUpdateAppointment = (
  serviceSector,
  store,
  datetime,
  customerInfo,
  orderId,
  pickupServiceId,
  hashed,
  xUpdateToken,
  appointmentMode,
  appointmentType,
  language,
  appointmentId,
  isPurchase
) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const dispatch = useCustomerDispatch();
  const isEditMode = appointmentMode === 'edit';

  const onSubmitBtn = async () => {
    const {
      firstnameValue,
      lastnameValue,
      mobileValue,
      emailValue,
      referencePhoneNumberValue
    } = customerInfo;
    const {servicesValue, annotationValue} = serviceSector;

    const postData = {
      email: emailValue,
      forename: firstnameValue,
      referencePhoneNumber: referencePhoneNumberValue,
      requestedServices: servicesIdsSelected(servicesValue),
      requestedTime: datetime.timeValue,
      surname: lastnameValue,
      tel: mobileValue,
      annotation: annotationValue
    };

    if (orderId) {
      postData[appointmentType === PICKUP ? 'orderId' : 'pickupServiceId'] = orderId;
      postData.hashed = hashed;
    }

    if (isEditMode) {
      postData.id = appointmentId;
    }

    setSubmitLoading(true);
    try {
      const storeId = store.value.code;

      if (isPurchase) {
        const {purchaseId} = await makePurchase({
          storeId,
          lang: language,
          appointmentType,
          postData
        });

        showOTP({dispatch}, {purchaseId});
      } else {
        const date = getISODate(new Date(datetime.dateValue));

        const {appointmentId} = isEditMode
          ? await updateAppointment({
              storeId,
              date,
              lang: language,
              headers: {'X-UPDATE-TOKEN': xUpdateToken},
              postData
            })
          : await makeAppointment({
              storeId,
              date,
              lang: language,
              postData
            });

        if (isEditMode) {
          successAppointment({dispatch});
        } else {
          showOTP({dispatch}, {appointmentId, appointmentMode: 'new'});
        }
      }
    } catch (error) {
      setSubmitError(error?.code ?? true);
    } finally {
      setSubmitLoading(false);
    }
  };

  return [onSubmitBtn, submitLoading, submitError, setSubmitError];
};

export default useCreateUpdateAppointment;
