import React, {createContext, useContext, useReducer, useMemo, useCallback} from 'react';

import {
  actionTypes,
  requestReducer,
  initialState,
  REQUEST_TYPES,
  REQUEST_FILTER
} from './requestReducer';
import useSafeDispatch from 'hooks/useSafeDispatch';

const RequestsContext = createContext();
RequestsContext.displayName = 'RequestsContext';

const RequestsDispatch = createContext();
RequestsDispatch.displayName = 'RequestsDispatch';

const RequestsProvider = ({children}) => {
  const [state, unsafeDispatch] = useReducer(requestReducer, initialState);

  const memoizedState = useMemo(() => state, [state]);
  const dispatch = useSafeDispatch(unsafeDispatch);

  return (
    <RequestsContext.Provider value={memoizedState}>
      <RequestsDispatch.Provider value={{dispatch}}>{children}</RequestsDispatch.Provider>
    </RequestsContext.Provider>
  );
};

function useRequestsState() {
  const context = useContext(RequestsContext);
  if (context === undefined) {
    throw new Error('useRequestsState must be used within a RequestsProvider');
  }

  return context;
}

function useRequestsDispatch() {
  const context = useContext(RequestsDispatch);
  if (context === undefined) {
    throw new Error('useRequestsDispatch must be used within a RequestsProvider');
  }

  const {dispatch} = context;

  const toggleDrawer = useCallback(
    (payload) => dispatch({type: actionTypes.toggleDrawer, payload}),
    [dispatch]
  );

  const setRequestType = useCallback(
    (payload) => dispatch({type: actionTypes.setRequestType, payload}),
    [dispatch]
  );

  const setRequestFilter = useCallback(
    (payload) => dispatch({type: actionTypes.setRequestFilter, payload}),
    [dispatch]
  );

  const setRequestsCount = useCallback(
    (payload) => dispatch({type: actionTypes.setRequestsCount, payload}),
    [dispatch]
  );

  const setRequestsData = useCallback(
    (payload) => dispatch({type: actionTypes.setRequestsData, payload}),
    [dispatch]
  );

  const initRequestsData = useCallback(() => dispatch({type: actionTypes.initRequestsData}), [
    dispatch
  ]);

  return {
    toggleDrawer,
    setRequestType,
    setRequestFilter,
    setRequestsData,
    setRequestsCount,
    initRequestsData,
    dispatch
  };
}

export {
  RequestsContext,
  RequestsProvider,
  useRequestsState,
  useRequestsDispatch,
  REQUEST_TYPES,
  REQUEST_FILTER
};
