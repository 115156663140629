import {useState, useEffect} from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleDatetime, setDatetime} from 'context/customer/actions';
import {fetchOpenDates, fetchAvailableSlots} from 'api/customer';
import {getISODate} from 'utils/time';
import {
  PICKUP,
  SERVICE_PICKUP,
  selectAppointmentType
} from 'components/Customer/Sector/ServiceSector/helper';

const useDatetime = () => {
  const {
    serviceSector: {sectorValue},
    appointmentType,
    store,
    datetime: {dateValue, timeValue, editable},
    orderId
  } = useCustomerState();
  const [selectedDate, setSelectedDate] = useState(dateValue);
  const [selectedTime, setSelectedTime] = useState(timeValue);
  const [openDates, setOpenDates] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [monthDatePicker, setMonthDatePicker] = useState(
    selectedDate ? moment(selectedDate).startOf('month') : moment().startOf('month')
  );
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSlots, setIsLoadingSlots] = useState(false);
  const dispatch = useCustomerDispatch();
  const {t} = useTranslation();

  const selectedCustomerType =
    orderId && appointmentType !== SERVICE_PICKUP ? PICKUP : sectorValue.code;
  const selectedAppointmentType = selectAppointmentType(appointmentType);
  const selectedStoreId = store.value?.code;

  /**
   * Effect for a selectedStore subsequently requests for open Dates
   */
  useEffect(() => {
    let mounted = true;
    async function getAvailableDates() {
      setError(false);
      setIsLoading(true);
      try {
        const availableDates = await fetchOpenDates({
          storeId: selectedStoreId,
          fromDate: getISODate(monthDatePicker)
        });
        if (mounted) {
          setOpenDates(availableDates.map((openDate) => new Date(openDate)));
          setIsLoading(false);
        }
      } catch (error) {
        if (mounted) {
          setIsLoading(false);
          setError(true);
        }
      }
    }
    getAvailableDates();

    return () => {
      mounted = false;
    };
  }, [selectedStoreId, monthDatePicker]);

  /**
   * Effect for selectedDate requests for available slots
   */
  useEffect(() => {
    let mounted = true;
    async function getAvailableTimeSlots() {
      setError(false);
      setIsLoadingSlots(true);
      try {
        const availableTimeSlots = await fetchAvailableSlots({
          storeId: selectedStoreId,
          date: getISODate(new Date(selectedDate)),
          customerType: selectedCustomerType,
          appointmentType: selectedAppointmentType
        });
        if (mounted) {
          setIsLoadingSlots(false);
          setAvailableSlots(availableTimeSlots);
        }
      } catch (error) {
        if (mounted) {
          setError(true);
          setIsLoadingSlots(false);
        }
      }
    }

    selectedDate && getAvailableTimeSlots();

    return () => {
      mounted = false;
    };
  }, [selectedDate, selectedCustomerType, selectedAppointmentType, selectedStoreId]);

  const onClickDate = (date) => {
    setSelectedDate(date);
    setSelectedTime('');
  };

  const onDecreaseMonth = (date, decreaseMonth) => {
    decreaseMonth();
    setSelectedDate('');
    setMonthDatePicker(moment(date).subtract(1, 'month').startOf('month'));
  };

  const onIncreaseMonth = (date, increaseMonth) => {
    increaseMonth();
    setSelectedDate('');
    setMonthDatePicker(getISODate(moment(date).add(1, 'month').startOf('month')));
  };

  const onClickTimeSlot = (time) => {
    setSelectedTime(time);
  };

  const closeSector = () =>
    setDatetime(
      {dispatch},
      {
        editable: false,
        selected: true,
        dateValue: selectedDate,
        timeValue: selectedTime
      }
    );

  const toggleSector = () => toggleDatetime({dispatch});

  const atLeastOneCompleted = Boolean(selectedDate) || Boolean(selectedTime);

  return {
    dateValue,
    editable,
    atLeastOneCompleted,
    toggleSector,
    selectedDate,
    isLoading,
    selectedTime,
    error,
    openDates,
    isLoadingSlots,
    availableSlots,
    onClickDate,
    onDecreaseMonth,
    onIncreaseMonth,
    onClickTimeSlot,
    closeSector,
    t
  };
};

export default useDatetime;
