import PublicFetch from 'utils/fetch';
import {API_CUSTOMER_URL} from 'config';
import {brandParam} from 'utils/brand';
import {md5ServiceList} from 'api/agent/helpers';

const publicFetch = new PublicFetch(API_CUSTOMER_URL);

const fetchServiceList = (customerType, lang, reporting = false) => {
  let url = `service/${customerType}/list?brand=${brandParam}&lang=${lang}`;

  if (reporting) {
    url += `&reporting=true`;
  }

  return publicFetch.get(url).then(({data}) => md5ServiceList(data));
};

const fetchOpenDates = async ({storeId, fromDate}) => {
  try {
    const {data} = await publicFetch.get(
      `store/${storeId}/dates-open${fromDate ? `?from-date=${fromDate}` : ''}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchBranches = async ({brand, coords, lang, appointmentType}) => {
  try {
    const {data} = await publicFetch.get(
      `store/branches?brand=${brand}&appointmentType=${appointmentType}&latitude=${coords.latitude}&longitude=${coords.longitude}&lang=${lang}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAvailableSlots = async ({storeId, date, customerType, appointmentType}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment/store/${storeId}/date/${date}/list?customerType=${customerType}&appointmentType=${appointmentType}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const makeAppointment = async ({storeId, date, lang, postData}) => {
  try {
    const {data} = await publicFetch.post(
      `appointment/store/${storeId}/date/${date}?brand=${brandParam}&lang=${lang}`,
      postData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const updateAppointment = async ({storeId, date, lang, headers, postData}) => {
  try {
    const {
      data
    } = await publicFetch.post(
      `appointment/store/${storeId}/date/${date}/update?brand=${brandParam}&lang=${lang}`,
      postData,
      {headers}
    );
    return data;
  } catch (error) {
    throw error;
  }
};

const confirmAppointment = async ({token}) => {
  try {
    await publicFetch.get(`appointment/confirm?token=${token}&brand=${brandParam}`);

    return;
  } catch (error) {
    throw error;
  }
};

const fetchAppointmentId = async ({token, lang}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment?editToken=${token}&brand=${brandParam}&lang=${lang}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchForUpdate = async ({appointmentId, headers, lang}) => {
  try {
    const {
      data
    } = await publicFetch.get(
      `appointment/getForUpdate?appointmentId=${appointmentId}&lang=${lang}`,
      {headers}
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const cancelAppointment = async ({data, headers, lang}) => {
  try {
    await publicFetch({
      url: `appointment?brand=${brandParam}&lang=${lang}`,
      method: 'delete',
      data,
      headers
    });
  } catch (error) {
    throw error;
  }
};

const confirmAppointmentWithOtp = async ({postData, lang}) => {
  try {
    const {data} = await publicFetch.post(
      `appointment/otp/confirm?lang=${lang}&brand=${brandParam}`,
      postData
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const confirmPurchaseWithOtp = async ({postData, lang}) => {
  try {
    const {data} = await publicFetch.post(
      `purchase/otp/confirm?lang=${lang}&brand=${brandParam}`,
      postData
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchAppointmentDetailsOtp = async ({postData, lang}) => {
  try {
    const {data, headers} = await publicFetch.post(
      `appointment/otp/details?lang=${lang}`,
      postData
    );

    return {data, headers};
  } catch (error) {
    throw error;
  }
};

const fetchPickupDetails = async ({hash, lang}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment/appointment/hash/${hash}?lang=${lang}&brand=${brandParam}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchServicePickupDetails = async ({hash, lang, appointmentType}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment/appointment/hash/${hash}?appointmentType=${appointmentType}&lang=${lang}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const generateAppointmentOTP = async ({appointmentId, lang}) => {
  try {
    const {data} = await publicFetch.get(
      `appointment/otp/generate?appointmentId=${appointmentId}&lang=${lang}&brand=${brandParam}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const generatePurchaseOTP = async ({purchaseId, lang}) => {
  try {
    const {data} = await publicFetch.get(
      `purchase/otp/generate?purchaseId=${purchaseId}&lang=${lang}&brand=${brandParam}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

const makePurchase = async ({storeId, lang, appointmentType, postData}) => {
  try {
    const {data} = await publicFetch.post(
      `purchase/store/${storeId}?brand=${brandParam}&lang=${lang}&appointmentType=${appointmentType}`,
      postData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export {
  fetchServiceList,
  fetchBranches,
  fetchOpenDates,
  fetchAvailableSlots,
  makeAppointment,
  updateAppointment,
  confirmAppointment,
  fetchAppointmentId,
  cancelAppointment,
  confirmAppointmentWithOtp,
  confirmPurchaseWithOtp,
  fetchAppointmentDetailsOtp,
  fetchForUpdate,
  fetchPickupDetails,
  fetchServicePickupDetails,
  generateAppointmentOTP,
  generatePurchaseOTP,
  makePurchase
};
