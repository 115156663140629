import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

import {adminRoute, usersRoute, timelineRoute, dashboardRoute} from 'utils/routes';
import {isCosmote} from 'utils/brand';
import {NotificationBadge as Badge} from 'components/common/Badge';
import {useRequestsState, useRequestsDispatch} from 'context/agent/requests/request-context';
import {useAuth} from 'context/agent/auth-context';
import {menuOptions as storeAppointmentMenu} from 'components/Agent/AgentHeader/StoreAppointmentDropdown';

const SidebarMenu = ({active, logout, isGermanosUser, handleMenu}) => {
  const {requestsCount} = useRequestsState();
  const {toggleDrawer} = useRequestsDispatch();
  const {
    authState,
    getStoreId,
    isUser,
    isAdmin,
    isOwner,
    isStoreManager,
    scheduleSettingsAccess
  } = useAuth();
  const {t} = useTranslation();
  const storeId = getStoreId();

  const sidebarClasses = classnames('sidebar-menu', {
    active: active
  });

  const menu = [
    {
      icon: 'fa-list-ul',
      href: timelineRoute(),
      label: t('SIDEBAR_MENU.calendar'),
      display: isUser,
      onClick: handleMenu
    },
    {
      icon: 'fa-clock',
      href: `/agent/${storeId}/schedule-settings`,
      label: t('SIDEBAR_MENU.schedule'),
      display: isGermanosUser || scheduleSettingsAccess,
      onClick: handleMenu,
      subMenu: storeAppointmentMenu(t, storeId, {isOwner, isStoreManager})
    },
    {
      icon: 'fa-chart-pie',
      href: dashboardRoute(),
      label: t('SIDEBAR_MENU.reports'),
      display: isUser,
      onClick: handleMenu
    },
    {
      icon: 'fa-store-alt',
      href: adminRoute,
      label: t('ADMIN:STORE_MANAGEMENT'),
      display: isAdmin,
      onClick: handleMenu
    },
    {
      icon: 'fa-user',
      href: usersRoute,
      label: t('ADMIN:USER_MANAGEMENT'),
      display: isAdmin,
      onClick: handleMenu
    }
  ];

  const dir = isCosmote ? 'cosmote' : 'germanos';

  return (
    <div className={sidebarClasses}>
      <div className="px-3 header header--agent d-flex justify-content-between align-items-center">
        <a href="/agent/timeline">
          <img
            src={require(`assets/images/${dir}/logo.png`)}
            className="logo logo--agent"
            alt="logo"
          />
        </a>
        <button className="close-button" onClick={handleMenu}>
          <i className="fa fa-times-circle"></i>
        </button>
      </div>
      <div className="menu-rows">
        {menu.map(
          ({icon, href, label, display, onClick, subMenu}, index) =>
            display && (
              <div key={index}>
                {!subMenu ? (
                  <NavLink
                    className="my-2 px-3 text-decoration-none d-flex align-items-center text-agent-primary"
                    activeClassName="menu-active"
                    to={href}
                    onClick={onClick}
                  >
                    <i className={`icon-header fa ${icon} mr-2`} /> {label}
                  </NavLink>
                ) : (
                  <div className="my-2 px-3 text-decoration-none d-flex align-items-center text-agent-primary">
                    <i className={`icon-header fa ${icon} mr-2`} /> {label}
                  </div>
                )}
                {subMenu &&
                  subMenu
                    .filter((opt) => opt.display)
                    .map(({key, label, href}) => (
                      <NavLink
                        key={key}
                        className="my-2 pl-5 px-3 text-decoration-none d-flex align-items-center text-agent-primary"
                        activeClassName="menu-active"
                        to={href}
                        onClick={onClick}
                      >
                        <i className={`icon-header far fa-dot-circle mr-2`} /> {label}
                      </NavLink>
                    ))}
              </div>
            )
        )}
        {isUser && (
          <div
            key="requests"
            className="my-2 px-3 text-decoration-none d-flex align-items-center text-agent-primary"
            onClick={toggleDrawer}
          >
            <Badge unit={requestsCount} className="mr-2">
              <i className="icon-header fa fa-question-circle mr-2" />
            </Badge>
            <span>{t('REQUESTS_TO_BUY')}</span>
          </div>
        )}
      </div>
      <div className="position-absolute bottom-0 w-100 bg-white ">
        <hr />
        <div
          className="my-4 px-3 d-flex justify-content-between align-items-center cursor-pointer"
          onClick={logout}
        >
          <div>
            <div className="d-flex fs-14 align-items-center">
              <span className="icon-header icon-header-store fa fa-user mr-3" />
              <div>
                {authState?.storename && <div>{authState?.storename}</div>}
                {authState?.email}
              </div>
            </div>
          </div>
          <span>{t('SIDEBAR_MENU.logout')}</span>
        </div>
      </div>
    </div>
  );
};

SidebarMenu.proptype = {
  active: PropTypes.bool.isRequired,
  isGermanosUser: PropTypes.bool.isRequired,
  handleMenu: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default SidebarMenu;
