import React, {Fragment, memo} from 'react';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Synopsis from '../Synopsis';
import ErrorMessage from 'components/common/ErrorMessage';
import Loader from 'components/common/Loader';
import DatetimeSynopsis from '../Synopsis/DatetimeSynopsis';
import NextStep from '../../common/NextStep';
import {dateFormatted} from 'utils/time';
import useDatetime from 'hooks/useDatetime';
import DatepickerHeader from 'components/common/DatepickerHeader';

const Datetime = () => {
  const {
    dateValue,
    editable,
    atLeastOneCompleted,
    toggleSector,
    selectedDate,
    isLoading,
    selectedTime,
    error,
    openDates,
    isLoadingSlots,
    availableSlots,
    onClickDate,
    onDecreaseMonth,
    onIncreaseMonth,
    onClickTimeSlot,
    closeSector,
    t
  } = useDatetime();

  const selDateOrValue = selectedDate || dateValue;

  return (
    <Fragment>
      <Synopsis
        title={t(selDateOrValue ? 'DATE_AND_TIME' : 'CHOOSE_APPOINTMENT_DATE')}
        selected={atLeastOneCompleted}
        expanded={editable}
        onToggleSector={toggleSector}
      >
        <DatetimeSynopsis dateValue={selDateOrValue} timeValue={selectedTime} t={t} />
      </Synopsis>
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center">
          <Loader />
        </div>
      )}
      {error && <ErrorMessage />}
      {!error && !isLoading && (
        <DatePicker
          renderCustomHeader={({date, decreaseMonth, increaseMonth}) => {
            const momentDate = moment(date);
            return (
              <DatepickerHeader
                label={momentDate.format('MMMM YYYY')}
                disabled={momentDate.isBefore(moment())}
                canDatetimeEdit
                onDecreaseMonth={() => onDecreaseMonth(momentDate, decreaseMonth)}
                onIncreaseMonth={() => onIncreaseMonth(momentDate, increaseMonth)}
              />
            );
          }}
          inline
          showDisabledMonthNavigation
          minDate={new Date()}
          selected={selectedDate}
          onChange={onClickDate}
          includeDates={openDates}
        />
      )}
      {!error && !isLoading && selectedDate && (
        <Fragment>
          <div className="my-2 text-center">
            {t('CHOOSE_APPOINTMENT_TIME')}:
            <span className="text-primary ml-1">{dateFormatted(selectedDate)}</span>
          </div>
          {isLoadingSlots && (
            <div className="mt-2 d-flex justify-content-center">
              <Loader />
            </div>
          )}
          <div className="mt-2 d-flex flex-wrap select-timeslots">
            {!isLoadingSlots &&
              availableSlots.map(({time, available}, index) => {
                const timeSlotClasses = classnames('time-slot cursor-pointer text-center', {
                  'mr-1': availableSlots.length - 1 !== index
                });

                const timeSlotBlockClasses = classnames('time-slot-block', {
                  selected: selectedTime === time,
                  disabled: !available
                });

                return (
                  <div
                    key={time}
                    className={timeSlotClasses}
                    onClick={() => (available ? onClickTimeSlot(time) : undefined)}
                  >
                    <div className={timeSlotBlockClasses} />
                    <small className="font-weight-semibold">{time.slice(0, -3)}</small>
                  </div>
                );
              })}
          </div>
        </Fragment>
      )}
      <NextStep display={Boolean(selectedDate && selectedTime)} onClick={closeSector} t={t} />
    </Fragment>
  );
};

export default memo(Datetime);
