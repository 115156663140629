import {useCallback, useReducer} from 'react';

import useSafeDispatch from 'hooks/useSafeDispatch';

const actionTypes = {
  idle: 'idle',
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected'
};

function asyncReducer(state, action) {
  switch (action.type) {
    case actionTypes.idle: {
      return {status: actionTypes.idle, data: null, error: null};
    }
    case actionTypes.pending: {
      return {status: actionTypes.pending, data: null, error: null};
    }
    case actionTypes.resolved: {
      return {status: actionTypes.resolved, data: action.data, error: null};
    }
    case actionTypes.rejected: {
      return {status: actionTypes.rejected, data: null, error: action.error};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function useAsync(initialState) {
  const [state, unsafeDispatch] = useReducer(asyncReducer, {
    status: actionTypes.idle,
    data: null,
    error: null,
    ...initialState
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const {data, error, status} = state;

  const run = useCallback(
    (promise) => {
      dispatch({type: actionTypes.pending});
      return promise.then(
        ({data} = {}) => {
          dispatch({type: actionTypes.resolved, data});
          return data;
        },
        (error) => {
          dispatch({type: actionTypes.rejected, error});
          throw error;
        }
      );
    },
    [dispatch]
  );

  const setIdle = useCallback(() => dispatch({type: actionTypes.idle}), [dispatch]);
  const setData = useCallback((data) => dispatch({type: actionTypes.resolved, data}), [dispatch]);
  const setError = useCallback((error) => dispatch({type: actionTypes.rejected, error}), [
    dispatch
  ]);
  const setPending = useCallback(() => dispatch({type: actionTypes.pending}), [dispatch]);

  return {
    isIdle: status === actionTypes.idle,
    isLoading: status === actionTypes.pending,
    isError: status === actionTypes.rejected,
    isSuccess: status === actionTypes.resolved,
    setIdle,
    setData,
    setError,
    setPending,
    error,
    status,
    data,
    run
  };
}

export {useAsync};
