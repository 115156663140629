import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleDatetime} from 'context/customer/actions';
import Sector from './';
import Datetime from 'components/Customer/Sector/Datetime';
import DatetimeSynopsis from 'components/Customer/Synopsis/DatetimeSynopsis';

const DatetimeCard = () => {
  const {t} = useTranslation();
  const {store, datetime} = useCustomerState();
  const dispatch = useCustomerDispatch();

  return (
    <Sector
      label={t('DATE_AND_TIME')}
      disabled={!store.selected}
      state={datetime}
      toggleSector={() => toggleDatetime({dispatch})}
      sectorExpanded={<Datetime />}
      sectorSynopsis={
        <DatetimeSynopsis {...datetime} onClick={() => toggleDatetime({dispatch})} t={t} />
      }
    />
  );
};

export default memo(DatetimeCard);
