import React, {lazy, Suspense} from 'react';
import {Route, Redirect, Switch, useRouteMatch} from 'react-router-dom';
import Layout from './Layout';
import Login from 'components/Agent/Login';
import Loader from 'components/common/Loader';
import {useAuth} from 'context/agent/auth-context';
import {isMobile} from 'utils/size';

import FetchProvider from 'context/agent/FetchProvider';
import RequestsProvider from 'context/agent/requests/RequestsProvider';

const AgentRouter = lazy(() => import('./Router'));

const AuthenticatedRoute = ({component: Component, ...rest}) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(ownProps) => {
        if (!auth.isAuthenticated()) {
          return <Redirect to="/agent/login" />;
        }

        if (auth.isAdmin) {
          return <Redirect to="/admin" />;
        }

        if (auth.isReporter) {
          return <Redirect to="/reports" />;
        }

        return <Component {...ownProps} {...rest} />;
      }}
    ></Route>
  );
};

const loaderIconDimension = isMobile ? 40 : 80;

const AgentRoutes = () => {
  const {url} = useRouteMatch();

  return (
    <Suspense
      fallback={
        <Loader
          wrapperClasses="d-flex flex-column justify-content-center align-items-center h-100 fs-20"
          width={loaderIconDimension}
          height={loaderIconDimension}
        />
      }
    >
      <FetchProvider>
        <RequestsProvider>
          <Switch>
            <Route exact path={`${url}/login`}>
              <Layout>
                <Login />
              </Layout>
            </Route>
            <AuthenticatedRoute path={url} component={AgentRouter} />
          </Switch>
        </RequestsProvider>
      </FetchProvider>
    </Suspense>
  );
};

export default AgentRoutes;
