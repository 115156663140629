import createReducer from 'utils/createReducer';
import {purchaseStatus} from './dto';

const REQUEST_TYPES = {
  pending: 'pending',
  completed: 'completed'
};

const REQUEST_FILTER = {
  [REQUEST_TYPES.completed]: {
    completed: purchaseStatus.COMPLETED,
    bookedAppointment: purchaseStatus.BOOKED_APPOINTMENT,
    notInterested: purchaseStatus.NOT_INTERESTED,
    notAnswered: purchaseStatus.NOT_RESPONDED,
    appointmentCanceled: purchaseStatus.APPOINTMENT_CANCELED
  },
  [REQUEST_TYPES.pending]: {
    pendingOrder: purchaseStatus.OUT_OF_STOCK,
    pending: purchaseStatus.PENDING,
    pendingAll: purchaseStatus.PENDING_ALL
  }
};

const PURCHASE_TYPES = {
  digitalCare: 'DIGITAL_CARE',
  purchaseRequest: 'PURCHASE_REQUEST'
};

const actionTypes = {
  init: 'init',
  toggleDrawer: 'toggleDrawer',
  setRequestType: 'setRequestType',
  setRequestFilter: 'setRequestFilter',
  setRequestsCount: 'setRequestsCount',
  setRequestsData: 'setRequestsData',
  initRequestsData: 'initRequestsData'
};

const initialState = {
  drawerVisible: false,
  requestsCount: 0,
  requests: [],
  requestOptions: {
    type: REQUEST_TYPES.pending,
    filter: REQUEST_FILTER.pending.pendingAll
  }
};

const requestReducer = createReducer(initialState, {
  [actionTypes.init]: () => initialState,
  [actionTypes.toggleDrawer]: (state, payload) => ({
    ...state,
    drawerVisible: payload === undefined ? !state.drawerVisible : payload
  }),
  [actionTypes.setRequestType]: (state, {type, filter}) => ({
    ...state,
    requestOptions: {
      ...state.requestOptions,
      type,
      filter
    }
  }),
  [actionTypes.setRequestFilter]: (state, payload) => ({
    ...state,
    requestOptions: {
      ...state.requestOptions,
      filter: payload
    }
  }),
  [actionTypes.setRequestsCount]: (state, payload) => ({
    ...state,
    requestsCount: isNaN(payload) ? 0 : payload
  }),
  [actionTypes.setRequestsData]: (state, payload) => ({
    ...state,
    requests: mergeRequests(state.requests, payload)
  }),
  [actionTypes.initRequestsData]: (state) => ({
    ...state,
    requests: []
  })
});

const mergeRequests = (prevState, requests) => {
  const obj = {};
  prevState.concat(requests).forEach((x) => (obj[x.id] = x));

  return [...Object.keys(obj).map((k) => obj[k])];
};

export {actionTypes, requestReducer, initialState, REQUEST_TYPES, REQUEST_FILTER, PURCHASE_TYPES};
