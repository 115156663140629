import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {fetchServicePickupDetails} from 'api/customer';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {showOTP, populateAppointment} from 'context/customer/actions';
import {SERVICE_PICKUP} from 'components/Customer/Sector/ServiceSector/helper';
import {updateAppointmentDetails} from 'components/Customer/SectorsForm/helper';

const useServicePickupDetails = () => {
  const [servicePickUpLoading, setServicePickUpLoading] = useState(false);
  const [servicePickUpError, setServicePickUpError] = useState(false);
  const dispatch = useCustomerDispatch();
  const urlParams = new URLSearchParams(decodeURIComponent(useLocation().search));

  const hash = urlParams.get('s');
  const {
    t,
    i18n: {language}
  } = useTranslation();

  useEffect(() => {
    async function fetch() {
      try {
        setServicePickUpLoading(true);
        const response = await fetchServicePickupDetails({
          hash,
          lang: language,
          appointmentType: SERVICE_PICKUP
        });
        if (response.appointmentId) {
          showOTP({dispatch}, {appointmentId: response.appointmentId, appointmentMode: 'edit'});
        } else {
          populateAppointment({dispatch}, updateAppointmentDetails(response, t));
        }
      } catch (error) {
        setServicePickUpError(error?.code ?? true);
      } finally {
        setServicePickUpLoading(false);
      }
    }

    if (false && hash) {
      fetch();
    }
  }, [hash, dispatch, language, t]);

  return [servicePickUpLoading, servicePickUpError, setServicePickUpError];
};

export default useServicePickupDetails;
