import React from 'react';

const MessageExclusion = ({children}) => (
  <div className="fs-14 mt-4">
    <i className="fa fa-exclamation-circle mt-1 text-warning" />
    {children}
  </div>
);

export default MessageExclusion;
