import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const PopulateFooter = ({token, onSubmitBtn}) => {
  const {t} = useTranslation();

  return (
    <div className="d-sm-flex justify-content-between align-items-center my-2 text-center">
      <Link
        to={`/appointment/cancel${token ? `?token=${token}` : ''}`}
        className="cursor-pointer text-decoration-none d-block d-sm-inline-block ml-sm-3"
      >
        {t('CANCEL_APPOINTMENT')}
      </Link>
      <Button
        variant="primary"
        className="rounded-pill btn-cover px-4 mt-3 mt-sm-0 font-weight-semibold"
        onClick={onSubmitBtn}
      >
        {t('SAVE')}
      </Button>
    </div>
  );
};

PopulateFooter.propTypes = {
  token: PropTypes.string,
  onSubmitBtn: PropTypes.func.isRequired
};

export default memo(PopulateFooter);
