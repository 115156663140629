import React, {memo} from 'react';
import PropTypes from 'prop-types';

const ServiceSectorSynopsis = ({orderId, servicesValue, onClick, t}) => (
  <div className="font-weight-semibold">
    <ul className="pl-3 mb-0">
      {servicesValue.map(({description, services}, index) => (
        <li key={index}>
          {!services ? description : `${description} - `}
          {orderId && (
            <span className="fs-14">
              {' '}
              ({t('ORDER_NUMBER')}: {orderId})
            </span>
          )}
          {services?.map(({description}) => description).join(', ')}
          {!orderId && onClick && (
            <button type="button" className="link-button ml-1" onClick={onClick}>
              {t('CHANGE')}
            </button>
          )}
        </li>
      ))}
    </ul>
  </div>
);

ServiceSectorSynopsis.propTypes = {
  orderId: PropTypes.string,
  servicesValue: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default memo(ServiceSectorSynopsis);
