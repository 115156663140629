import React, {Fragment, memo} from 'react';
import Form from 'react-bootstrap/Form';
import {useTranslation} from 'react-i18next';
import Synopsis from '../../Synopsis';
import ServiceSectorSynopsis from '../../Synopsis/ServiceSectorSynopsis';
import ServiceList from './ServiceList';
import MessageExclusion from './MessageExclusion';
import Loader from '../../../common/Loader';
import ErrorMessage from '../../../common/ErrorMessage';
import NextStep from '../../../common/NextStep';
import {MAX_RESIDENTIAL_SERVICES, MAX_BUSINESS_SERVICES} from './helper';

import useServiceSector from 'hooks/useServiceSector';

const ServiceSector = () => {
  const {
    t,
    i18n: {language}
  } = useTranslation();
  const {
    sectorValue,
    editable,
    orderId,
    closeSector,
    toggleSector,
    isLoading,
    error,
    atLeastOneSelected,
    list,
    restriction,
    atLeastOneMobileServiceSelected,
    moreInfo,
    exclusion,
    exclusionTechExpert,
    isResidential,
    annotation,
    treeServices,
    onChangeAnnotation,
    onChangeService
  } = useServiceSector({
    language
  });

  const {title} = sectorValue;

  return (
    <Fragment>
      <Synopsis
        title={isResidential ? title : `${title} - ${treeServices[0].description}`}
        selected
        onToggleSector={toggleSector}
        expanded={editable}
      >
        <ServiceSectorSynopsis
          orderId={orderId}
          sectorValue={sectorValue}
          servicesValue={treeServices[0].services}
          t={t}
        />
      </Synopsis>
      {isLoading && <Loader />}
      {error && <ErrorMessage />}
      {!error && !isLoading && list.length > 0 && (
        <Fragment>
          {isResidential && <div>{t('APPOINTMENT_CONCERNS')}:</div>}
          <ServiceList
            list={list}
            restriction={restriction}
            isResidential={isResidential}
            onChangeService={onChangeService}
            t={t}
          />
          {restriction && (
            <div className="fs-14 text-error mt-1">
              {t('RESTRICTION_SERVICES_PLURAL', {
                number: isResidential ? MAX_RESIDENTIAL_SERVICES : MAX_BUSINESS_SERVICES
              })}
            </div>
          )}
          {exclusion && (
            <MessageExclusion>
              <strong className="ml-1">{t('EXCLUDED_SERVICES')}</strong>
              <div className="ml-1">
                {[
                  t('BILL_PAYMENT'),
                  t('TIME_RENEWAL'),
                  t('FIXED_LINE_REPLACEMENT'),
                  t('SIM_REPLACEMENT')
                ].map((item, index) => (
                  <strong key={index} className="d-block d-flex">
                    <span className="mr-2">-</span>
                    {item}
                  </strong>
                ))}
              </div>
            </MessageExclusion>
          )}
          {exclusionTechExpert && (
            <MessageExclusion>
              <strong className="ml-1">{t('BUYING_PRODUCTS_EXCLUDED')}</strong>
            </MessageExclusion>
          )}
          <Form.Group controlId="service-textarea" className="mt-4">
            <Form.Label>{`${t(moreInfo)} (${t('OPTIONAL')})`}</Form.Label>
            <Form.Control
              value={annotation}
              onChange={onChangeAnnotation}
              placeholder={`${atLeastOneMobileServiceSelected ? t('PLACEHOLDER_ANNOTATION') : ''}`}
              as="textarea"
              rows={3}
            />
          </Form.Group>
          <NextStep display={atLeastOneSelected} onClick={closeSector} t={t} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default memo(ServiceSector);
