import moment from 'moment';

const dateFormatted = (date) => moment(date || new Date()).format('YYYY-MM-DD');

const timelineRoute = (dateParam) => {
  return `/agent/timeline/${dateFormatted(dateParam)}`;
};

const dashboardRoute = (fromDate, toDate) => {
  return `/agent/dashboard/${dateFormatted(
    fromDate || moment().subtract(1, 'months')
  )}to${dateFormatted(toDate)}`;
};

const adminRoute = '/admin';
const usersRoute = '/admin/users';
const reportsRoute = '/reports';
const reportsDetails = (storeId, dateRange) => `/reports/${storeId}/${dateRange}`;
const loginNextRoute = (isAdmin) => (isAdmin ? adminRoute : timelineRoute());
const storeHoursRoute = (storeId = '') => `/agent/${storeId}/store-hours`;
const scheduleSettingsRoute = (storeId = '') => `/agent/${storeId}/schedule-settings`;

export {
  adminRoute,
  timelineRoute,
  dashboardRoute,
  usersRoute,
  reportsRoute,
  reportsDetails,
  loginNextRoute,
  storeHoursRoute,
  scheduleSettingsRoute
};
