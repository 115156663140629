import * as React from 'react';
import axios from 'axios';
import {AGENT_API_URL} from 'config';

import {useAuth} from 'context/agent/auth-context';

const FetchContext = React.createContext();

function FetchProvider(props) {
  const auth = useAuth();
  const authAxios = axios.create({
    baseURL: AGENT_API_URL
  });

  authAxios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${auth.authState.jwt}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error && error.response ? error.response.status : 0;

      if ([401, 403].includes(status)) {
        auth.logout();

        return Promise.reject(error);
      }

      if (status === 1 || (status >= 400 && status <= 500 && error.response.data)) {
        if ([401, 403].includes(status)) {
          auth.logout();
        }

        if (error.response.data.code) {
          return Promise.reject(error.response.data);
        }

        if (error.response.data.data) {
          return Promise.reject(error.response.data.data);
        }

        if (error.response.data.error) {
          return Promise.reject(error.response.data.error);
        }
      }

      return Promise.reject(error);
    }
  );

  return <FetchContext.Provider value={{authAxios}} {...props} />;
}

function useAuthFetch() {
  const context = React.useContext(FetchContext);

  if (context === undefined) {
    throw new Error('useAuthFetch must be used within an FetchProvider');
  }

  return context;
}

export {FetchProvider, FetchContext, useAuthFetch};
