import {
  customerTypes,
  residential,
  residentialList
} from 'components/Customer/Sector/ServiceSector/helper';

const updateAppointmentDetails = (
  {
    appointmentType,
    appointmentTypes,
    customerType,
    services,
    storeName,
    storeId,
    date,
    time,
    forename,
    surname,
    tel,
    email,
    referencePhoneNumber,
    annotation,
    orderId,
    hashed
  },
  t
) => {
  const sectorValue = customerTypes(t).find(({code}) => code === customerType.toLowerCase());
  const populatePayload = {
    serviceSector: {
      selected: true,
      sectorValue,
      servicesValue: (residential(sectorValue.code) ? residentialList(services) : services)[0]
        .services,
      annotationValue: annotation
    },
    store: {
      selected: true,
      value: {code: storeId, title: storeName}
    }
  };

  if (date && time) {
    populatePayload.datetime = {
      selected: true,
      dateValue: new Date(date),
      timeValue: time
    };
  }

  if (forename && surname) {
    populatePayload.customerInfo = {
      selected: true,
      firstnameValue: forename,
      lastnameValue: surname,
      mobileValue: tel,
      emailValue: email,
      referencePhoneNumberValue: referencePhoneNumber
    };
  }

  if (orderId) {
    populatePayload.orderId = orderId;
    populatePayload.hashed = hashed;
  }

  if (appointmentType) {
    populatePayload.appointmentType = appointmentType ?? '';
  } else if (appointmentTypes) {
    populatePayload.appointmentType = appointmentTypes?.[0] ?? '';
  }

  return populatePayload;
};

export {updateAppointmentDetails};
