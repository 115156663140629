import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import errorIcon from 'assets/images/common/icons/error.svg';
import {errors} from 'utils/error';

const ErrorMessage = ({wrapperClasses, width, code, onClick}) => {
  const {t} = useTranslation();

  return (
    <div className={`text-center ${wrapperClasses}`} style={{zIndex: 1}}>
      <img src={errorIcon} width={width} className="mb-4" alt="error" />
      <div>
        {typeof code === 'number' && errors[code] ? (
          t(`ERRORS:${errors[code]}`)
        ) : (
          <Fragment>
            {t('SOMETHING_WENT_WRONG')}
            <br />
            {t('PLEASE_TRY_AGAIN')}
          </Fragment>
        )}
      </div>
      {onClick && (
        <button className="btn btn-primary mt-4" onClick={onClick}>
          {t('PLEASE_TRY_AGAIN')}
        </button>
      )}
    </div>
  );
};

ErrorMessage.defaultProps = {
  code: '',
  width: 48
};

ErrorMessage.propTypes = {
  wrapperClasses: PropTypes.string,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  width: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

export default ErrorMessage;
