import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleCustomerInfo, setCustomerInfo} from 'context/customer/actions';
import {validateCustomerInfo} from 'utils/validation';

const useCustomerInfo = () => {
  const {
    customerInfo: {
      firstnameValue,
      lastnameValue,
      mobileValue,
      emailValue,
      referencePhoneNumberValue
    }
  } = useCustomerState();
  const [firstname, setFirstname] = useState({
    value: firstnameValue,
    touched: false
  });
  const [lastname, setLastname] = useState({value: lastnameValue, touched: false});
  const [mobile, setMobile] = useState({value: mobileValue, touched: false});
  const [email, setEmail] = useState({value: emailValue, touched: false});
  const [referencePhoneNumber, setReferencePhoneNumber] = useState({
    value: referencePhoneNumberValue,
    touched: false
  });
  const [terms, setTerms] = useState(Boolean(lastnameValue));
  const dispatch = useCustomerDispatch();
  const {
    t,
    i18n: {language}
  } = useTranslation();

  const onChange = ({value}, setField) => {
    setField((fieldState) => ({
      ...fieldState,
      value
    }));
  };

  const onBlur = (_, setField) => {
    setField((fieldState) => ({
      ...fieldState,
      touched: true
    }));
  };

  const closeSector = () =>
    setCustomerInfo(
      {dispatch},
      {
        editable: false,
        selected: true,
        firstnameValue: firstname.value,
        lastnameValue: lastname.value,
        mobileValue: mobile.value,
        emailValue: email.value,
        referencePhoneNumberValue: referencePhoneNumber.value
      }
    );

  const toggleSector = () => toggleCustomerInfo({dispatch});

  const onChangeTerms = ({target: {checked}}) => {
    setTerms(checked);
  };

  const {formIsValid, errors} = validateCustomerInfo(
    firstname.value,
    lastname.value,
    mobile.value,
    email.value,
    referencePhoneNumber.value,
    t
  );

  const fields = [
    {
      id: 'firstname',
      value: firstname.value,
      type: 'text',
      label: t('NAME'),
      mandatory: true,
      error: errors.firstname,
      setState: setFirstname,
      md: 6,
      touched: firstname.touched
    },
    {
      id: 'lastname',
      value: lastname.value,
      type: 'text',
      label: t('LAST_NAME'),
      mandatory: true,
      error: errors.lastname,
      setState: setLastname,
      md: 6,
      touched: lastname.touched
    },
    {
      id: 'mobile',
      value: mobile.value,
      type: 'text',
      label: t('MOBILE_PHONE'),
      mandatory: true,
      error: errors.mobile,
      setState: setMobile,
      md: 6,
      touched: mobile.touched
    },
    {
      id: 'email',
      value: email.value,
      type: 'email',
      label: t('EMAIL'),
      mandatory: false,
      error: errors.email,
      setState: setEmail,
      md: 6,
      touched: email.touched
    },
    {
      id: 'referencePhoneNumber',
      value: referencePhoneNumber.value,
      type: 'text',
      label: t('REFERENCE_PHONE_NUMBER'),
      mandatory: false,
      error: errors.referencePhoneNumber,
      setState: setReferencePhoneNumber,
      md: 6,
      touched: referencePhoneNumber.touched
    }
  ];

  const atLeastOneCompleted =
    Boolean(firstname) || Boolean(lastname) || Boolean(mobile) || Boolean(email);

  return {
    language,
    atLeastOneCompleted,
    closeSector,
    fields,
    firstname,
    lastname,
    email,
    mobile,
    referencePhoneNumber,
    terms,
    formIsValid: formIsValid && terms,
    onChangeTerms,
    toggleSector,
    onChange,
    onBlur,
    t
  };
};

export default useCustomerInfo;
