import React, {memo, useRef, useLayoutEffect} from 'react';
import usePrevious from './usePrevious';

export const SingleInput = memo(({focus, autoFocus, ...rest}) => {
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <input ref={inputRef} {...rest} />;
});

export default SingleInput;
