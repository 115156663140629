export const getAppointmentById = (req, res, ctx) => {
  return res(
    ctx.delay(100),
    ctx.status(202, 'Mocked status'),
    ctx.json({
      appointmentId: 'string',
      date: '2020-09-08',
      email: 'joe-doe@example.com',
      forename: 'Joe',
      services: ['5f4e0136179a793f0c89d2ef', '5f4e0136179a793f0c89d2ee'],
      customerType: 'RESIDENTIAL',
      storeId: 'QWE@!#!@WSQEWQE@!#',
      storeName: 'COSMOTE-1',
      surname: 'Doe',
      tel: '6932222111',
      time: '18:00:00'
    })
  );
};

export const stopAppointment = (req, res, ctx) => {
  return res(ctx.delay(100), ctx.status(202, 'Mocked status'), ctx.json({}));
};
