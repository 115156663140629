import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleStore} from 'context/customer/actions';
import Sector from './';
import Store from 'components/Customer/Sector/Store';
import StoreSynopsis from 'components/Customer/Synopsis/StoreSynopsis';

const StoreCard = () => {
  const {t} = useTranslation();
  const {store, serviceSector, orderId} = useCustomerState();
  const dispatch = useCustomerDispatch();
  const storeTr = t('STORE');
  const orderExists = Boolean(orderId);

  return (
    <Sector
      label={storeTr}
      disabled={!serviceSector.selected || orderExists}
      state={store}
      toggleSector={() => toggleStore({dispatch})}
      sectorExpanded={<Store />}
      sectorSynopsis={
        <StoreSynopsis {...store} orderId={orderId} onClick={() => toggleStore({dispatch})} t={t} />
      }
    />
  );
};

export default memo(StoreCard);
