import businessList from 'mocks/data/business_services.json';
import residentialList from 'mocks/data/residential_services.json';

const getServicesList = (req, res, ctx) => {
  const {customerType} = req.params;

  if (customerType === 'business') {
    return res(ctx.delay(100), ctx.status(202, 'Mocked status'), ctx.json(businessList));
  }

  return res(ctx.delay(100), ctx.status(202, 'Mocked status'), ctx.json(residentialList));
};

export {getServicesList};
