import {useEffect, useState} from 'react';
import {fetchServiceList} from 'api/customer';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleService, setService} from 'context/customer/actions';
import {
  nOfServicesSelected,
  residential,
  displayRestriction,
  mobileServiceSelected,
  displayExclusion,
  displayExclusionTechExpert,
  getMoreInfo,
  transformList,
  treeSelected,
  listAfterSelection,
  residentialList
} from 'components/Customer/Sector/ServiceSector/helper';

function useServiceSector({language}) {
  const {
    serviceSector: {sectorValue, servicesValue, annotationValue, editable, orderId}
  } = useCustomerState();
  const [serviceSector, setServiceSector] = useState({
    isLoading: false,
    error: false,
    list: []
  });
  const [annotation, setAnnotation] = useState(annotationValue);
  const dispatch = useCustomerDispatch();
  const treeServices = treeSelected(serviceSector.list);

  useEffect(() => {
    let mounted = true;
    const fetchServices = async (code) => {
      try {
        setServiceSector((prevState) => ({
          ...prevState,
          isLoading: true,
          error: false
        }));
        const response = await fetchServiceList(code, language);

        if (mounted) {
          setServiceSector((prevState) => ({
            ...prevState,
            list: transformList(
              residential(sectorValue.code) ? residentialList(response) : response,
              servicesValue
            )
          }));
        }
      } catch {
        if (mounted) {
          setServiceSector((prevState) => ({
            ...prevState,
            error: true
          }));
        }
      } finally {
        if (mounted) {
          setServiceSector((prevState) => ({
            ...prevState,
            isLoading: false
          }));
        }
      }
    };
    if (sectorValue.code) {
      fetchServices(sectorValue.code);
    }

    return () => {
      mounted = false;
    };
  }, [language, sectorValue.code, servicesValue]);

  const closeSector = () =>
    setService(
      {dispatch},
      {
        editable: false,
        selected: true,
        servicesValue: treeSelected(serviceSector.list),
        annotationValue: annotation
      }
    );

  const toggleSector = () => toggleService({dispatch});

  const onChangeService = (el, customId, value, level) => {
    if (el && el.target.className.indexOf('preventClick') > -1) {
      return;
    }
    setServiceSector((prevState) => {
      const list = prevState.list.map((item) => ({
        ...item,
        selected: level === 0 ? customId === item.customId : item.selected,
        services: item.services.map((serviceLevel1) => ({
          ...serviceLevel1,
          selected:
            level === 1 && customId === serviceLevel1.customId ? value : serviceLevel1.selected,
          services: serviceLevel1.services?.map((service) => ({
            ...service,
            selected:
              (level === 1 && customId === serviceLevel1.customId && !value) ||
              (level === 2 && customId === service.customId)
                ? value
                : service.selected
          }))
        }))
      }));

      return {
        ...prevState,
        list: listAfterSelection(list)
      };
    });
  };

  const onChangeAnnotation = ({target}) => {
    setAnnotation(target.value);
  };

  const {isLoading, error, list} = serviceSector;
  const isResidential = residential(sectorValue.code);
  const numberOfServicesSelected = nOfServicesSelected(list);
  const atLeastOneSelected = numberOfServicesSelected > 0;
  const moreInfo = getMoreInfo(list);
  const restriction = displayRestriction(isResidential, numberOfServicesSelected);
  const atLeastOneMobileServiceSelected = mobileServiceSelected(list);
  const exclusion = displayExclusion(list);
  const exclusionTechExpert = displayExclusionTechExpert(list);

  return {
    sectorValue,
    editable,
    orderId,
    closeSector,
    toggleSector,
    isLoading,
    error,
    atLeastOneSelected,
    list,
    restriction,
    atLeastOneMobileServiceSelected,
    moreInfo,
    exclusion,
    exclusionTechExpert,
    isResidential,
    annotation,
    treeServices,
    onChangeAnnotation,
    onChangeService
  };
}

export default useServiceSector;
