import PublicFetch from 'utils/fetch';
import {AGENT_API_URL} from 'config';
import {brandParam} from 'utils/brand';
import {md5AppointmentServices} from 'api/agent/helpers';
const publicFetch = new PublicFetch(AGENT_API_URL);

export async function authenticate(body) {
  try {
    const {data} = await publicFetch.post('auth/signin', body);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function signup(body) {
  try {
    const {data} = await publicFetch.post('auth/signup', body);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const makeAgentAppointment = async (authAxios, {storeId, date, postData, lang}) => {
  try {
    await authAxios.post(
      `appointment/store/${storeId}/date/${date}/?lang=${lang}&brand=${brandParam}`,
      postData
    );
    return;
  } catch (error) {
    throw error;
  }
};

export const updateAgentAppointment = async (authAxios, {storeId, date, postData}) => {
  try {
    await authAxios.post(
      `appointment/store/${storeId}/date/${date}/update?brand=${brandParam}`,
      postData
    );
    return;
  } catch (error) {
    throw error;
  }
};

export const fetchAppointment = async (authAxios, {appointmentId, lang}) => {
  try {
    const {data} = await authAxios.get(`appointment/${appointmentId}?lang=${lang}`);

    return {
      ...data,
      services: md5AppointmentServices(data.services, data.customerType),
      processedServices: md5AppointmentServices(data.processedServices)
    };
  } catch (error) {
    throw error;
  }
};

export const fetchBookedAppointments = async (authAxios, {date, lang, storeId}) => {
  try {
    const {data} = await authAxios.get(
      `appointment/booked?date=${date}&lang=${lang}&storeId=${storeId}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchAppointmentCount = async (authAxios, {month, year, storeId}) => {
  try {
    const {data} = await authAxios.get(
      `appointment/store/${storeId}/count?month=${month}&year=${year}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const cancelAppointment = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.delete(
      `appointment/${appointmentId}/cancel?brand=${brandParam}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const startAppointment = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.post(`appointment/${appointmentId}/start`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const stopAppointment = async ({
  authAxios,
  agentName,
  appointmentId,
  agentNote,
  processedServiceIds,
  subStatus,
  brandParam
}) => {
  try {
    const {data} = await authAxios.post(`appointment/${appointmentId}/stop`, {
      agentName,
      agentNote,
      processedServiceIds,
      subStatus,
      brand: brandParam
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const commentAppointment = async ({authAxios, appointmentId, comment}) => {
  try {
    await authAxios.post(`appointment/${appointmentId}/comment`, {comment});
  } catch (error) {
    throw error;
  }
};

export const noShowAppointment = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.put(`appointment/${appointmentId}/noShow`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCommentsAppointment = async ({authAxios, appointmentId}) => {
  try {
    const {data} = await authAxios.get(`appointment/${appointmentId}/comment`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPeriodReports = async ({authAxios, fromDate, toDate, lang}) => {
  try {
    const {data} = await authAxios.get(
      `report/store/fromDate/${fromDate}/toDate/${toDate}?lang=${lang}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getStoreList = async ({authAxios}) => {
  try {
    const {data} = await authAxios.get('store/branches/list');
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async ({authAxios, userId}) => {
  try {
    const {data} = await authAxios.get(`user/${userId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async ({authAxios, data}) => {
  try {
    await authAxios.post('user', data);
  } catch (error) {
    throw error;
  }
};

export const updateUser = async ({authAxios, userId, data}) => {
  try {
    await authAxios.put(`user/${userId}`, data);
  } catch (error) {
    throw error;
  }
};

export const amendCompletedAppointment = async ({
  authAxios,
  agentName,
  appointmentId,
  agentNote,
  processedServiceIds,
  brandParam
}) => {
  try {
    const {data} = await authAxios.put(`appointment/${appointmentId}/amend`, {
      agentNote,
      agentName,
      processedServiceIds,
      brand: brandParam
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getHealthStatus = async () => {
  try {
    const {data} = await publicFetch.get('appointment/health');
    return data;
  } catch (error) {
    throw error;
  }
};

export const exportStoreDetails = async ({authAxios, date}) => {
  try {
    const {data} = await authAxios.get(`/store/date/${date}/export?brand=${brandParam}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const exportUsersDetails = async ({authAxios}) => {
  try {
    const {data} = await authAxios.get('/user/users/export');
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async ({token, password}) => {
  try {
    const {data} = await publicFetch.put(`/user/password/update`, {
      editToken: token,
      password
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async ({email, brand = brandParam}) => {
  try {
    const {data} = await publicFetch.post(`/user/password/reset?brand=${brand}`, {email});
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchDailySchedule = async ({authAxios, storeId, date, appointmentType}) => {
  try {
    return await authAxios.get(
      `store/${storeId}/date/${date}/dailySchedule?appointmentType=${appointmentType}`
    );
  } catch (error) {
    throw error;
  }
};

export const getStoreInspectorsList = async ({authAxios}) => {
  try {
    return await authAxios.get('/inspector/inspectors/list');
  } catch (error) {
    throw error;
  }
};

export const bulkUpdateStoreInspector = async ({authAxios, payload}) => {
  try {
    const {data} = await authAxios.put('/inspector/stores', payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAreaManagersList = async ({authAxios}) => {
  try {
    return await authAxios.get('/inspector/managers/list');
  } catch (error) {
    throw error;
  }
};
