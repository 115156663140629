import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ServiceBox from './ServiceBox';
import {PICKUP, SERVICE_PICKUP} from './helper';

const ServiceList = ({list, restriction, isResidential, onChangeService, t}) => (
  <Fragment>
    {!isResidential && (
      <div className="mt-1 text-primary cursor-pointer d-flex fs-14 fs-md-16">
        {list.map(({customId, description, selected}) => {
          const listClasses = classnames('py-2 px-3 mw-50', {
            'font-weight-semibold': selected,
            'bg-gray-light': selected
          });

          return (
            <div
              key={customId}
              onClick={(el) => onChangeService(el, customId, !selected, 0)}
              className={listClasses}
            >
              {description}
            </div>
          );
        })}
      </div>
    )}
    <div className="bg-gray-light p-2">
      {list
        .find(({selected}) => selected)
        .services.map(({customId, description, services, selected, disabled, appointmentType}) =>
          Boolean(services) ? (
            <Fragment key={customId}>
              <Form.Check
                id={`checkbox-${customId}`}
                label={description}
                value={customId}
                type="checkbox"
                disabled={(!selected && restriction) || disabled}
                checked={selected}
                onChange={(el) => onChangeService(el, customId, !selected, 1)}
              />
              <Collapse in={selected}>
                <div id={`collapse-${customId}`}>
                  <div className="box-sector-service d-sm-flex my-2 fs-14">
                    {services.map(
                      ({customId, description, selected, info, url, isMobileService}) => {
                        const boxClasses = classnames('mb-2 mr-sm-2', {
                          active: selected
                        });

                        return isMobileService ? (
                          <OverlayTrigger
                            key={customId}
                            placement="bottom"
                            overlay={
                              <Popover
                                id={`popover-info-${customId}`}
                                className="popover-service-info"
                              >
                                <Popover.Content>{info}</Popover.Content>
                              </Popover>
                            }
                          >
                            <Card className={boxClasses}>
                              <ServiceBox
                                restriction={restriction}
                                customId={customId}
                                description={description}
                                url={url}
                                selected={selected}
                                onChangeService={onChangeService}
                                t={t}
                              />
                            </Card>
                          </OverlayTrigger>
                        ) : (
                          <Card key={customId} className={boxClasses}>
                            <ServiceBox
                              restriction={restriction}
                              customId={customId}
                              description={description}
                              url={url}
                              info={info}
                              selected={selected}
                              onChangeService={onChangeService}
                              t={t}
                            />
                          </Card>
                        );
                      }
                    )}
                  </div>
                </div>
              </Collapse>
            </Fragment>
          ) : (
            <Fragment key={customId}>
              {![PICKUP, SERVICE_PICKUP].includes(appointmentType) && (
                <Form.Check
                  id={`checkbox-${customId}`}
                  label={description}
                  value={customId}
                  type="checkbox"
                  disabled={(!selected && restriction) || disabled}
                  checked={selected}
                  onChange={(el) => onChangeService(el, customId, !selected, 1)}
                />
              )}
            </Fragment>
          )
        )}
    </div>
  </Fragment>
);

ServiceList.propTypes = {
  list: PropTypes.array.isRequired,
  restriction: PropTypes.bool.isRequired,
  isResidential: PropTypes.bool.isRequired,
  onChangeService: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(ServiceList);
