import {
  TOGGLE_SERVICE,
  SET_CUSTOMER_TYPE,
  SET_SERVICE,
  TOGGLE_STORE,
  SET_STORE,
  TOGGLE_DATETIME,
  SET_DATETIME,
  TOGGLE_CUSTOMERINFO,
  SET_CUSTOMERINFO,
  POPULATE_APPOINTMENT,
  SUCCESS_APPOINTMENT,
  SHOW_OTP,
  SUCCESS_OTP
} from 'context/customer/constants/ActionTypes';

const toggleService = ({dispatch}) => dispatch({type: TOGGLE_SERVICE});
const setCustomerType = ({dispatch}, payload) => dispatch({type: SET_CUSTOMER_TYPE, payload});
const setService = ({dispatch}, payload) => dispatch({type: SET_SERVICE, payload});
const toggleStore = ({dispatch}) => dispatch({type: TOGGLE_STORE});
const setStore = ({dispatch}, payload) => dispatch({type: SET_STORE, payload});
const toggleDatetime = ({dispatch}) => dispatch({type: TOGGLE_DATETIME});
const setDatetime = ({dispatch}, payload) => dispatch({type: SET_DATETIME, payload});
const toggleCustomerInfo = ({dispatch}) => dispatch({type: TOGGLE_CUSTOMERINFO});
const setCustomerInfo = ({dispatch}, payload) => dispatch({type: SET_CUSTOMERINFO, payload});
const populateAppointment = ({dispatch}, payload) =>
  dispatch({type: POPULATE_APPOINTMENT, payload});
const successAppointment = ({dispatch}) => dispatch({type: SUCCESS_APPOINTMENT});
const showOTP = ({dispatch}, payload) => dispatch({type: SHOW_OTP, payload});
const successOTP = ({dispatch}, payload) => dispatch({type: SUCCESS_OTP, payload});

export {
  toggleService,
  setCustomerType,
  setService,
  toggleStore,
  setStore,
  toggleDatetime,
  setDatetime,
  toggleCustomerInfo,
  setCustomerInfo,
  populateAppointment,
  successAppointment,
  showOTP,
  successOTP
};
