import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import {dateFormatted} from 'utils/time';

const DatetimeSynopsis = ({dateValue, timeValue, onClick, t}) => (
  <div className="font-weight-semibold">
    {dateValue && (
      <Fragment>
        <div>
          {dateFormatted(dateValue)}
          {onClick && (
            <button type="button" className="link-button ml-1" onClick={onClick}>
              {t('CHANGE')}
            </button>
          )}
        </div>
        {timeValue && <div>{timeValue.substring(0, 5)}</div>}
      </Fragment>
    )}
  </div>
);

DatetimeSynopsis.propTypes = {
  dateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  timeValue: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default memo(DatetimeSynopsis);
