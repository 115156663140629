import React from 'react';
import {Button, Form, Card} from 'react-bootstrap';
import classnames from 'classnames';
import {useFormik} from 'formik';
import {Link} from 'react-router-dom';

import Loader from 'components/common/Loader';
import {useAsync} from 'hooks/useAsync';
import updatePasswordSchema from 'validations/updatePasswordSchema';
import {changePassword} from 'api/agent';
import {errors as apiErrors} from 'utils/error';

function UpdatePasswordForm({isMobile, t, token, cta = ''}) {
  const {run, isLoading, isSuccess, error, setIdle} = useAsync();
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isValid,
    touched,
    errors,
    resetForm
  } = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    isInitialValid: false,
    onSubmit: () => run(changePassword({token, password: values.password}).then(resetForm)),
    validationSchema: updatePasswordSchema
  });
  const onCloseSuccess = () => setIdle();
  const cardClasses = classnames('bg-white', {
    'p-20': isMobile,
    'p-30': !isMobile
  });
  const loaderIconDimension = isMobile ? 40 : 80;
  const title = cta.includes('create') ? t('CREATE_PASSWORD') : t('RESET_PASSWORD');

  return (
    <>
      <Form className="login-form" onSubmit={handleSubmit}>
        {isSuccess && (
          <Card className="mb-4">
            <Card.Body
              className={[cardClasses, 'd-flex justify-content-between align-items-center'].join(
                ' '
              )}
            >
              <div>
                <i className="fas fa-check-circle text-primary mr-2"></i>
                <strong>
                  {t('VALIDATION:VALID_PASSWORD_UPDATE')}{' '}
                  <Link to="/agent/login" className="text-primary">
                    {t('LOGIN')}
                  </Link>
                </strong>
              </div>
              <button className="close-button" onClick={onCloseSuccess}>
                <i className="fa fa-times-circle"></i>
              </button>
            </Card.Body>
          </Card>
        )}
        <Card key="resetForm">
          {isLoading && (
            <Loader
              wrapperClasses="my-5 fs-20 fs-md-26"
              width={loaderIconDimension}
              height={loaderIconDimension}
            />
          )}
          {!isSuccess && !isLoading && (
            <>
              <Card.Body className={cardClasses}>
                <h3 className="fs-18 font-weight-bold">{title}</h3>
                {error && (
                  <div className="login-form-error fs-16 mt-5">
                    {t(`ERRORS:${apiErrors[error.code] ?? 'GENERAL_ERROR'}`)}
                  </div>
                )}
                <Form.Group className="mt-5">
                  <Form.Label className="mb-3">{t('PASSWORD')}</Form.Label>
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    placeholder={t('LOGIN_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={!!errors.password && touched.password}
                  />

                  {touched.password && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.password)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mt-5">
                  <Form.Label className="mb-3">{t('PASSWORD_CONFIRM')}</Form.Label>
                  <Form.Control
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    placeholder={t('LOGIN_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                    isInvalid={!!errors.passwordConfirm && touched.passwordConfirm}
                  />

                  {touched.passwordConfirm && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.passwordConfirm)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Card.Body>
              <Card.Footer className={cardClasses}>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-3d w-48 fs-14 float-right"
                  disabled={!isValid}
                >
                  {t('SAVE')}
                </Button>
              </Card.Footer>
            </>
          )}
        </Card>
      </Form>
    </>
  );
}

export default UpdatePasswordForm;
