import faker from 'faker';

const createMockStore = (_, index) => {
  return {
    id: faker.git.shortSha,
    outletId: `G11${index}`,
    name: faker.address.secondaryAddress,
    address: faker.address.streetAddress(),
    city: faker.address.stateAbbr,
    zipCode: faker.address.zipCode(),
    disabled: faker.random.boolean()
  };
};

const Stores = () => {
  let stores = [];
  return () => {
    stores = [...new Array(20)].map(createMockStore);
    return stores;
  };
};

export const getStores = (req, res, ctx) => {
  const onGetStore = Stores();
  return res(ctx.delay(100), ctx.status(202, 'Mocked status'), ctx.json(onGetStore()));
};
