import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';

const CardFlowWrapper = ({bodyClasses, children}) => (
  <Card className="rounded-meniscus card-flow-wrapper text-secondary">
    <Card.Body className={bodyClasses}>{children}</Card.Body>
  </Card>
);

CardFlowWrapper.propTypes = {
  bodyClasses: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default memo(CardFlowWrapper);
