import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {el, en} from '../../locales';
import {useLanguage} from 'context/agent/language-context';
import {isCosmote} from 'utils/brand';

const {assetDirecory, domain} = require('utils/brand');

const Header = ({i18n}) => {
  const {updateLanguage} = useLanguage();
  const onChangeLanguage = (locale) => updateLanguage(locale);

  const languages = [
    {
      label: 'Ελληνικά',
      type: el,
      classes: classnames('border-right pr-2', {
        'selected-language': i18n.language === el
      })
    },
    {
      label: 'English',
      type: en,
      classes: classnames('pl-2', {
        'selected-language': i18n.language === en
      })
    }
  ];

  return (
    <header className="header container d-flex justify-content-between align-items-center">
      <a href={domain}>
        <img src={require(`assets/images/${assetDirecory}/logo.png`)} className="logo" alt="logo" />
      </a>
      {isCosmote && (
        <div className="cursor-pointer fs-12 text-secondary">
          {languages.map(({label, classes, type}) => (
            <span
              key={label}
              className={classes}
              onClick={type === i18n.language ? undefined : () => onChangeLanguage(type)}
            >
              {label}
            </span>
          ))}
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired
  }).isRequired
};

export default Header;
