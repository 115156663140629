import * as yup from 'yup';

export default yup.object().shape({
  password: yup
    .string()
    .required('VALIDATION:FIELD_REQUIRED')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-’/`~!#*$@_%+=.,^&(){}|;:”<>?[\]])[A-Za-z\d-’/`~!#*$@_%+=.,^&(){}|;:”<>?[\]]{12,}$/g,
      'VALIDATION:INVALID_PASSWORD'
    ),
  passwordConfirm: yup
    .mixed()
    .required('VALIDATION:FIELD_REQUIRED')
    .oneOf([yup.ref('password')], 'VALIDATION:INVALID_PASSWORD_CONFIRMATION')
});
