import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import {useHistory} from 'react-router-dom';

import {adminRoute, usersRoute} from 'utils/routes';

const cogItems = (t) => [
  {
    label: t('ADMIN:STORES'),
    key: 'stores',
    route: adminRoute
  },
  {
    label: t('ADMIN:USERS'),
    key: 'users',
    route: usersRoute
  }
];

const CogDropdown = ({t}) => {
  const history = useHistory();

  return (
    <Dropdown bsPrefix="custom-dropdown">
      <Dropdown.Toggle variant="none" id="menu-cog">
        <div className="text-center px-1">
          <i className="icon-header fa fa-cog" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        {cogItems(t).map(({key, label, route}) => (
          <Dropdown.Item key={key} onClick={() => history.push(route)}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

CogDropdown.propTypes = {
  t: PropTypes.func.isRequired
};

export default CogDropdown;
