import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import 'moment/locale/el';
import {registerLocale} from 'react-datepicker';
import el from 'date-fns/locale/el';
import 'i18n';
import Customer from 'components/Customer';
import Appointment from 'components/Customer/Appointment';
import Authentication from 'components/Authentication';
import ThemeSelector from 'components/Theme/ThemeSelector';
import {LanguageProvider} from 'context/agent/language-context';
import {CustomerProvider} from 'context/customer/customer-context';
import {displayFavicon} from 'utils/brand';
import * as serviceWorker from './serviceWorker';

registerLocale('el', el);
displayFavicon();

if (process.env.REACT_APP_MOCKAPI === 'true') {
  const {mockApiWorker} = require('mockApiWorker');
  mockApiWorker.start();
}

ReactDOM.render(
  <ThemeSelector>
    <LanguageProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <CustomerProvider>
              <Customer />
            </CustomerProvider>
          </Route>
          <Route path="/appointment">
            <CustomerProvider>
              <Appointment />
            </CustomerProvider>
          </Route>
          <Route path="/pickup">
            <CustomerProvider>
              <Customer />
            </CustomerProvider>
          </Route>
          <Route path="/(agent|admin|reports)">
            <Authentication />
          </Route>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </LanguageProvider>
  </ThemeSelector>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
