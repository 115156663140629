import {useState, useEffect, useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleStore, setStore} from 'context/customer/actions';
import {fetchBranches} from 'api/customer';
import {selectAppointmentType} from 'components/Customer/Sector/ServiceSector/helper';
import {getGeoCoords, getGeoCode} from 'services/geolocationService';
import {apiKey} from 'utils/google';
import {isCosmote} from 'utils/brand';

import cosmoteBrand from 'assets/images/common/cosmote.svg';
import germanosBrand from 'assets/images/common/germanos.svg';

const useStore = () => {
  const {
    appointmentType,
    store: {editable, value, options}
  } = useCustomerState();
  const autoCompleteRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchStores, setSearchStores] = useState({fetched: false, options});
  const [selectedStore, setSelectedStore] = useState(value);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useCustomerDispatch();
  const {
    t,
    i18n: {language}
  } = useTranslation();

  const handleScriptLoad = (setSearchText, autoCompleteRef) => {
    const autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      types: ['geocode', 'establishment'],
      componentRestrictions: {country: 'gr'}
    });
    window.google.maps.event.addListener(autoComplete, 'place_changed', () => {
      getAddress(autoComplete, setSearchText);
    });
  };

  const scriptLoadCallback = useCallback(handleScriptLoad, []);

  useEffect(() => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      scriptLoadCallback(setSearchText, autoCompleteRef);
    } else {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`, () =>
        scriptLoadCallback(setSearchText, autoCompleteRef)
      );
    }
  }, [scriptLoadCallback]);
  /**
   * Pojo for the branch response object
   * @param {*} branch
   */
  const parseStore = (branch) => {
    const {id, brand, distance, address, city, landline, zipCode, location, ...rest} = branch;

    const brandLowerCase = brand.toLowerCase();

    return {
      label: `${brand} ${location ? ` - ${location}` : ''} ${distance ? `(${distance} km)` : ''}`,
      title: `${address}, ${city} ${zipCode}${landline ? `, ${landline}` : ''}`,
      brandClass: `text-${brandLowerCase}-primary`,
      code: id,
      icon: (() => (brandLowerCase === 'cosmote' ? cosmoteBrand : germanosBrand))(),
      ...rest
    };
  };

  const onFindNearestStore = async () => {
    try {
      const {coords} = await getGeoCoords();
      const {results} = await getGeoCode(coords, language);
      if (results.length > 0) {
        setSearchText(results[0].formatted_address);
        onSearch(coords);
      }
    } catch {
      setError(true);
    }
  };

  const onSearch = async (location) => {
    try {
      setIsLoading(true);
      setSearchStores({
        fetched: false,
        options: []
      });
      const stores = await fetchBranches({
        brand: isCosmote ? 'all' : 'germanos',
        coords: location,
        lang: language,
        appointmentType: selectAppointmentType(appointmentType)
      });

      setSearchStores({fetched: true, options: stores.map((store) => parseStore(store))});
      setError(false);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  const onChangeSearchStore = ({target: {value}}) => {
    setSelectedStore(searchStores.options.find(({code}) => code === value));
  };

  const closeSector = () =>
    setStore(
      {dispatch},
      {
        editable: false,
        selected: true,
        value: selectedStore,
        options: searchStores.options
      }
    );

  const toggleSector = () => toggleStore({dispatch});

  const loadScript = (url, callback) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const getAddress = (autoComplete, setSearchText) => {
    const place = autoComplete.getPlace();
    if (place.geometry) {
      setSearchText(place.formatted_address);
      onSearch({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
      });
    }
  };

  return {
    editable,
    autoCompleteRef,
    setSearchText,
    error,
    onFindNearestStore,
    onChangeSearchStore,
    searchText,
    isLoading,
    closeSector,
    toggleSector,
    storeCompleted: Boolean(selectedStore.code),
    searchStores,
    selectedStore,
    t
  };
};

export default useStore;
