import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const {assetDirecory} = require('utils/brand');

const Synopsis = ({title, selected, expanded, children, onToggleSector}) => {
  const wrapperClasses = classnames('d-flex justify-content-between', {
    'text-shade-gray-67': selected
  });

  const arrowIconSrc = expanded
    ? require(`assets/images/${assetDirecory}/icons/arrow-up.svg`)
    : require(`assets/images/${assetDirecory}/icons/arrow-down.svg`);

  return (
    <Fragment>
      <div className={wrapperClasses}>
        <span className={`fs-md-${selected ? '14' : '22'} fs-${selected ? '14' : '18'}`}>
          {title}
        </span>
        <img
          src={arrowIconSrc}
          className="wi-16 wi-md-25 cursor-pointer"
          alt="selected sector"
          onClick={onToggleSector}
        />
      </div>
      {children}
      {expanded && <hr className="my-2 my-md-3" />}
    </Fragment>
  );
};

Synopsis.defaultProps = {
  selected: false,
  expanded: false
};

Synopsis.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  selected: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onToggleSector: PropTypes.func
};

export default memo(Synopsis);
