import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import styled from '@emotion/styled';
import {Button} from 'react-bootstrap';
import CardFlowWrapper from './CardFlowWrapper';
import Success from 'components/common/Success';

import {useCustomerState} from 'context/customer/customer-context';
import {downloadICSFile, getCalendarDates} from 'utils/calendar';
import {brandUpperCase} from 'utils/brand';

const AppointmentConfirmed = () => {
  const {t} = useTranslation();
  const {isPurchase, datetime, serviceSector, store} = useCustomerState();

  const title = t('BRAND_APPOINTMENT', {brand: brandUpperCase});
  const description = `${t('STORE_NAME', {name: store.value.name})} - ${t('ADDRESS_STORE', {
    address: store.value.title
  })}`;
  const calendarDates = getCalendarDates(datetime, serviceSector?.sectorValue?.code);

  return (
    <CardFlowWrapper bodyClasses="py-5 px-3 px-md-5 text-center">
      <Success
        title={`${t(`APPOINTMENT_CONFIRMED_TITLE${isPurchase ? '_PURCHASE_REQUEST' : ''}`)}`}
        desc={`${t(`APPOINTMENT_CONFIRMED_DESC${isPurchase ? '_PURCHASE_REQUEST' : ''}`)}`}
        t={t}
      >
        {!isPurchase && (
          <div className="small">
            <Divider />
            <div className="mb-2">{t('RECEIPTS_VISIT_STORE')}</div>
            <div className="text-left">
              {[t('ID_CARD_OR_PASSPORT'), t('VAT_NUMBER')].map((text, index) => (
                <div key={index} className="d-flex">
                  <span className="mr-1">-</span>
                  <span>{text}</span>
                </div>
              ))}
            </div>
            <Divider />
            <Button
              href={downloadICSFile(title, description, calendarDates?.start, calendarDates?.end)}
              target="_self"
              download="event-calendar"
              rel="noopener noreferrer"
              variant="primary"
              className="rounded-pill btn-cover px-4 mt-2 font-weight-semibold text-decoration-none"
            >
              <i className="fa fa-calendar-day mr-3" />
              {t('ADD_TO_CALENDAR')}
            </Button>
          </div>
        )}
      </Success>
    </CardFlowWrapper>
  );
};

const Divider = styled('div')`
  border-top: 2px solid #ddd;
  margin: 20px 0;
`;

export default memo(AppointmentConfirmed);
