import md5 from 'md5';

const RESIDENTIAL = 'residential';
const BUSINESS = 'business';
const TECH_EXPERTS = 'TECH_EXPERTS';
const MOBILE_SERVICE = 'MOBILE_SERVICE';
const REGULAR_SERVICE = 'REGULAR_SERVICE';
const PICKUP = 'PICKUP';
const SERVICE_PICKUP = 'SERVICE_PICKUP';
const PURCHASE_REQUEST = 'PURCHASE_REQUEST';
const DIGITAL_CARE = 'DIGITAL_CARE';
const SMART_LIVING = 'SMART_LIVING';

const MAX_RESIDENTIAL_SERVICES = 2;
const MAX_BUSINESS_SERVICES = 3;

const customerTypes = (t) => [
  {title: t('RESIDENTIAL'), code: RESIDENTIAL},
  {title: t('BUSINESS'), code: BUSINESS}
];

const residentialList = (data) =>
  data.map((item) => ({
    ...item,
    services: [
      {
        description: item.group,
        services: item.services
      }
    ]
  }));

const isSpecificService = (type, service) => type === service;

const transformService = (service, type, editIds, level1) => {
  const obj = {
    ...service,
    customId: service?.id ?? md5(type + service.description),
    selected: commonEls(getSameOrChildrenServiceIds(service), editIds)
  };

  if (level1) {
    obj.isPurchaseRequestCategory = service?.appointmentType === PURCHASE_REQUEST;
    obj.isDigitalCareCategory = service?.appointmentType === DIGITAL_CARE;
    obj.isPickUpCategory = service?.appointmentType === PICKUP;
    obj.isSmartLivingCategory = service?.appointmentType === SMART_LIVING;
    obj.isTechExpertCategory =
      service.services?.some(({appointmentType}) =>
        isSpecificService(appointmentType, TECH_EXPERTS)
      ) ?? false;
    obj.isMobileServiceCategory =
      service.services?.some(({appointmentType}) =>
        isSpecificService(appointmentType, MOBILE_SERVICE)
      ) ?? false;
    obj.disabled = false;
  }

  if (service.id) {
    obj.isTechExpert = isSpecificService(service?.appointmentType, TECH_EXPERTS);
    obj.isMobileService = isSpecificService(service?.appointmentType, MOBILE_SERVICE);
  }

  return obj;
};

const transformList = (data, selServices) => {
  const editIds = getIdsOnEdit(selServices);
  const descriptions = data[0].services.map(({description}) => description);

  const list = data[0].services.map((item, index) => ({
    ...item,
    customId: md5(item.description),
    selected: descriptions.includes(selServices[0].description)
      ? commonEls(getSameOrChildrenServiceIds(item), editIds)
      : index === 0,
    services: item.services.map((service) => ({
      ...transformService(service, item.description, editIds, true),
      services: service.services?.map((service) =>
        transformService(service, item.description, editIds)
      )
    }))
  }));

  return listAfterSelection(list);
};

const nOfServicesSelected = (list) => {
  if (list.length === 0) {
    return 0;
  }

  return list
    .find(({selected}) => selected)
    .services.reduce((acc, {selected, services}) => {
      const nofNestedSelected = services?.filter(({selected}) => selected).length ?? 0;
      return acc + (selected && !Boolean(services) ? 1 : 0) + nofNestedSelected;
    }, 0);
};

const getIdsOnEdit = (list) => leafIds(list[0]) ?? [];

const getSameOrChildrenServiceIds = (list) => leafIds(list) ?? [list.id];

const leafIds = (list) =>
  list?.services?.reduce((acc, {id, services}) => {
    const selectedIds = services?.filter(({id}) => id).map(({id}) => id);
    if (selectedIds) {
      acc.push(...selectedIds);
    }
    if (id) {
      acc.push(id);
    }
    return acc;
  }, []);

const servicesIdsSelected = (list) =>
  (list.find(({selected}) => selected) || list[0]).services.reduce(
    (acc, {id, selected, services}) => {
      const selectedIds = services?.filter(({selected, id}) => selected || id).map(({id}) => id);
      if (selectedIds) {
        acc.push(...selectedIds);
      }
      if ((selected || id) && !services) {
        acc.push(id);
      }
      return acc;
    },
    []
  );

const treeSelected = (list) => {
  if (list.length === 0) {
    return [{description: '', services: []}];
  }

  const firstLevel = list.filter(({selected}) => selected);
  const otherLevels = firstLevel[0].services
    .filter(({selected}) => selected)
    .map((item) => ({...item, services: item.services?.filter(({selected}) => selected)}));

  return [
    {
      ...firstLevel[0],
      services: [...otherLevels]
    }
  ];
};

const displayRestriction = (isResidential, number) =>
  isResidential ? number === MAX_RESIDENTIAL_SERVICES : number === MAX_BUSINESS_SERVICES;

const mobileServiceSelected = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.filter(({services}) => services)
    .reduce((acc, {services}) => acc.concat(services), [])
    .filter(({isMobileService}) => isMobileService)
    .some(({selected}) => selected);
};

const displayExclusion = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.filter(
      ({services, isPurchaseRequestCategory, isDigitalCareCategory, isSmartLivingCategory}) =>
        !services && !isPurchaseRequestCategory && !isDigitalCareCategory && !isSmartLivingCategory
    )
    .some(({selected}) => selected);
};

const displayExclusionTechExpert = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.some(({selected, isTechExpertCategory}) => selected && isTechExpertCategory);
};

const pickUpSelected = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.some(({selected, isPickUpCategory}) => selected && isPickUpCategory);
};

const purchaseProductSelected = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.some(
      ({selected, isPurchaseRequestCategory}) => selected && isPurchaseRequestCategory
    );
};

const digitalCareSelected = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.some(({selected, isDigitalCareCategory}) => selected && isDigitalCareCategory);
};

const smartLivingSelected = (list) => {
  if (list.length === 0) {
    return false;
  }
  return list
    .find(({selected}) => selected)
    .services.some(({selected, isSmartLivingCategory}) => selected && isSmartLivingCategory);
};

const getAppointmentType = ({servicesValue}) =>
  servicesValue
    .flatMap(({services}) => services)
    .reduce((acc, {services, appointmentType}) => {
      if (services) {
        acc.push(...services.flatMap(({appointmentType}) => appointmentType));
      } else {
        acc.push(appointmentType);
      }
      return acc;
    }, [])
    .filter((item, index, array) => array.indexOf(item) === index)
    .filter(Boolean)[0];

const selectAppointmentType = (appointmentType) =>
  [TECH_EXPERTS, SERVICE_PICKUP, SMART_LIVING].includes(appointmentType)
    ? appointmentType
    : REGULAR_SERVICE;

const commonEls = (arr1, arr2) => arr1.some((item) => arr2.includes(item));

const residential = (code) => code === RESIDENTIAL;

const disableCategoryService = (
  {services},
  {
    id,
    isMobileServiceCategory,
    isTechExpertCategory,
    isPickUpCategory,
    isPurchaseRequestCategory,
    isDigitalCareCategory,
    isSmartLivingCategory
  }
) => {
  const nonServiceOrExpertSel = services.some(({selected, id}) => id && selected);

  const firstLevelNotPickUp = services.some(
    ({selected, id, isPickUpCategory, isDigitalCareCategory, isSmartLivingCategorySel}) =>
      id && selected && (!isPickUpCategory || !isDigitalCareCategory || !isSmartLivingCategorySel)
  );

  const firstLevelNotPurchaseRequest = services.some(
    ({selected, id, isPurchaseRequestCategory}) => id && selected && !isPurchaseRequestCategory
  );

  const firstLevelNotDigitalCare = services.some(
    ({selected, id, isDigitalCareCategory}) => id && selected && !isDigitalCareCategory
  );

  const firstLevelNotSmartLiving = services.some(
    ({selected, id, isSmartLivingCategory}) => id && selected && !isSmartLivingCategory
  );

  const isMobileServiceCategorySel = services.some(
    ({selected, isMobileServiceCategory}) => isMobileServiceCategory && selected
  );

  const isTechExpertCategorySel = services.some(
    ({selected, isTechExpertCategory}) => isTechExpertCategory && selected
  );

  const isPickUpCategorySel = services.some(
    ({selected, isPickUpCategory}) => isPickUpCategory && selected
  );

  const isSmartLivingCategorySel = services.some(
    ({selected, isSmartLivingCategory}) => isSmartLivingCategory && selected
  );

  const isPurchaseSel = services.some(
    ({selected, isPurchaseRequestCategory, isDigitalCareCategory}) =>
      (isPurchaseRequestCategory || isDigitalCareCategory) && selected
  );

  if (id) {
    if (isPickUpCategory) {
      return firstLevelNotPickUp || isMobileServiceCategorySel || isTechExpertCategorySel;
    } else if (isPurchaseRequestCategory) {
      return firstLevelNotPurchaseRequest || isMobileServiceCategorySel || isTechExpertCategorySel;
    } else if (isDigitalCareCategory) {
      return firstLevelNotDigitalCare || isMobileServiceCategorySel || isTechExpertCategorySel;
    } else if (isSmartLivingCategory) {
      return firstLevelNotSmartLiving;
    }
    return (
      isMobileServiceCategorySel ||
      isTechExpertCategorySel ||
      isPickUpCategorySel ||
      isPurchaseSel ||
      isSmartLivingCategorySel
    );
  }
  if (isMobileServiceCategory) {
    return nonServiceOrExpertSel || isTechExpertCategorySel;
  }
  if (isTechExpertCategory) {
    return nonServiceOrExpertSel || isMobileServiceCategorySel;
  }

  return false;
};

const listAfterSelection = (list) =>
  list.map((item) => ({
    ...item,
    services: item.services.map((serviceLevel1) => ({
      ...serviceLevel1,
      disabled: item.selected ? disableCategoryService(item, serviceLevel1) : serviceLevel1.disabled
    }))
  }));

const getMoreInfo = (list) => {
  if (purchaseProductSelected(list)) {
    return 'MORE_INFO_PURCHASE_PRODUCT';
  } else if (digitalCareSelected(list)) {
    return 'MORE_INFO_DIGITAL_CARE';
  } else if (smartLivingSelected(list)) {
    return 'MORE_INFO_SMART_LIVING';
  }

  return 'MORE_INFO';
};

export {
  RESIDENTIAL,
  BUSINESS,
  PICKUP,
  SERVICE_PICKUP,
  PURCHASE_REQUEST,
  DIGITAL_CARE,
  MAX_RESIDENTIAL_SERVICES,
  MAX_BUSINESS_SERVICES,
  customerTypes,
  nOfServicesSelected,
  servicesIdsSelected,
  residential,
  displayRestriction,
  mobileServiceSelected,
  pickUpSelected,
  getAppointmentType,
  selectAppointmentType,
  displayExclusion,
  displayExclusionTechExpert,
  getMoreInfo,
  transformList,
  treeSelected,
  listAfterSelection,
  residentialList
};
