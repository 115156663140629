import React, {memo} from 'react';
import PropTypes from 'prop-types';

const DatepickerHeader = ({label, disabled, onDecreaseMonth, onIncreaseMonth, canDatetimeEdit}) => (
  <div className="d-flex justify-content-between align-items-center">
    <button onClick={onDecreaseMonth} className="btn" disabled={disabled || !canDatetimeEdit}>
      <i className="fa fa-chevron-left" />
    </button>
    <div className="react-datepicker__current-month">{label}</div>
    <button onClick={onIncreaseMonth} className="btn" disabled={!canDatetimeEdit}>
      <i className="fa fa-chevron-right" />
    </button>
  </div>
);

DatepickerHeader.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onDecreaseMonth: PropTypes.func.isRequired,
  onIncreaseMonth: PropTypes.func.isRequired,
  canDatetimeEdit: PropTypes.bool.isRequired
};

export default memo(DatepickerHeader);
