import React, {memo} from 'react';
import PropTypes from 'prop-types';

const StoreSynopsis = ({orderId, value, onClick, t}) => (
  <div className="font-weight-semibold">
    {value.code && (
      <div>
        {value.title}
        {!orderId && onClick && (
          <button type="button" className="link-button ml-1" onClick={onClick}>
            {t('CHANGE')}
          </button>
        )}
      </div>
    )}
  </div>
);

StoreSynopsis.propTypes = {
  orderId: PropTypes.string,
  value: PropTypes.shape({
    title: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default memo(StoreSynopsis);
