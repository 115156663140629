import {getTomorrow} from 'utils/time';

export const login = (req, res, ctx) => {
  const {body} = req;

  if (!body.username || !body.password) {
    return res(
      ctx.delay(1000),
      ctx.status(401, 'Mocked status'),
      ctx.json({
        message: 'Wrong user name or password'
      })
    );
  }

  return res(
    ctx.delay(1000),
    ctx.status(202, 'Mocked status'),
    ctx.json({
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
      agent: {
        name: 'John Doe',
        username: body.username,
        store: 'Store 1'
      },
      expiresAt: getTomorrow()
    })
  );
};
