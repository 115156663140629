import * as React from 'react';
import {useHistory} from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const AuthContext = React.createContext();

/**
 *
 * @param {React.PropsWithChildren} props, React Props
 * @returns{JSX.Element}
 */
function AuthProvider(props) {
  const agent = localStorage.getItem('agent');

  const [authState, setAuthState] = React.useState(agent ? JSON.parse(agent) : {});

  function setAuthInfo(data) {
    localStorage.setItem('agent', JSON.stringify(data));
    setAuthState(data);
  }

  const value = React.useMemo(
    () => ({authState, setAuthState: (authInfo) => setAuthInfo(authInfo)}),
    [authState]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

/**
 * The useAuth custom hook uses React.useContext to get the provided context value
 * from the nearest AuthProvider
 * @returns {Object} AuthState
 */
function useAuth() {
  const context = React.useContext(AuthContext);
  const history = useHistory();

  if (context === undefined) {
    throw new Error('useAuthState mush be used within an AuthProvider');
  }

  const {authState, setAuthState} = context;

  function isAuthenticated() {
    if (!authState?.jwt) {
      return false;
    }

    const {exp} = jwtDecode(authState.jwt);

    return new Date().getTime() < new Date(exp * 1000).getTime();
  }

  function logout() {
    localStorage.removeItem('agent');

    setAuthState({});

    history.push('/agent/login');
  }

  function getStoreId() {
    try {
      const {storeid} = jwtDecode(authState.jwt);
      return storeid;
    } catch (error) {
      // fail silently
    }
  }

  const isAdmin = authState?.role?.includes('ROLE_ADMIN');
  const isUser = authState?.role
    ? [
        'ROLE_USER',
        'ROLE_TECH_EXPERT',
        'ROLE_STORE_MANAGER',
        'ROLE_ASSISTANT_MANAGER',
        'ROLE_OWNER',
        'ROLE_SMART_LIVING'
      ].includes(authState.role[0])
    : false;

  const isOwner = authState?.role?.includes('ROLE_OWNER');
  const isStoreManager = authState?.role?.includes('ROLE_STORE_MANAGER');
  const scheduleSettingsAccess = [
    'ROLE_STORE_MANAGER',
    'ROLE_ASSISTANT_MANAGER',
    'ROLE_SMART_LIVING'
  ].includes(authState?.role?.[0]);
  const isReporter = authState?.role?.includes('ROLE_REPORTER');

  return {
    authState,
    setAuthState,
    isAuthenticated,
    logout,
    getStoreId,
    isAdmin,
    isReporter,
    isUser,
    isOwner,
    isStoreManager,
    scheduleSettingsAccess
  };
}

export {AuthProvider, useAuth};
