import * as React from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {setDefaultLocale} from 'react-datepicker';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import {el} from 'locales';

const LanguageContext = React.createContext();

const changeLanguage = (i18n, lang, setLanguage) => {
  i18n.changeLanguage(lang);
  setLanguage(lang);
  setDefaultLocale(lang);
  moment.locale(lang);
  localStorage.setItem('language', lang);
};

function LanguageProvider(props) {
  const persistedLanguage = localStorage.getItem('language') || el;
  const {i18n} = useTranslation();

  const [language, setLanguage] = React.useState(persistedLanguage);

  React.useEffect(() => {
    changeLanguage(i18n, persistedLanguage, setLanguage);
  }, [i18n, persistedLanguage]);

  const value = React.useMemo(() => {
    const localizedMoment = (...args) => {
      return moment(...args).locale(language);
    };

    return {
      language,
      setLanguage,
      moment: localizedMoment
    };
  }, [language]);

  return <LanguageContext.Provider value={value} {...props} />;
}

function useLanguage() {
  const {i18n, t} = useTranslation();
  const context = React.useContext(LanguageContext);

  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }

  const {language, setLanguage, moment} = context;

  const updateLanguage = (lang) => {
    changeLanguage(i18n, lang, setLanguage);
  };

  return {
    updateLanguage,
    language,
    moment,
    t
  };
}

export {LanguageProvider, LanguageContext, useLanguage};
