import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {useTranslation} from 'react-i18next';

const NotificationBox = ({message, onClose}) => {
  const {t} = useTranslation();

  return (
    <Card className="notification-box position-absolute rounded-0">
      <Card.Body className="p-4">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="font-weight-bold">{t('NEXT_APPOINTMENT_IN')}</span>
          <button className="close-button" onClick={onClose}>
            <i className="fa fa-times-circle"></i>
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <span className="font-weight-bold text-primary fs-46">{message}&apos;</span>
          <Button variant="primary" type="submit" className="fs-14">
            Περισσότερα
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
NotificationBox.proptype = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default NotificationBox;
