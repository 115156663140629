import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button';

const btnLabel = (isPurchase, btnEnabled, t) => {
  if (isPurchase && btnEnabled) {
    return t(`BTN_LABEL_COMPLETED_PURCHASE_REQUEST`);
  }

  return t(`BTN_LABEL_${btnEnabled ? 'COMPLETED_FORM' : 'NEXT'}`);
};

const Footer = ({btnEnabled, isPurchase, onSubmitBtn}) => {
  const {t} = useTranslation();

  return (
    <div className="text-center">
      <Button
        variant="primary"
        disabled={!btnEnabled}
        className="rounded-pill btn-cover px-4 mt-2 font-weight-semibold"
        onClick={onSubmitBtn}
      >
        {btnLabel(isPurchase, btnEnabled, t)}
      </Button>
    </div>
  );
};

Footer.propTypes = {
  btnEnabled: PropTypes.bool.isRequired,
  isPurchase: PropTypes.bool.isRequired,
  onSubmitBtn: PropTypes.func.isRequired
};

export default memo(Footer);
