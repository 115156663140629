import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import {brandUpperCase} from 'utils/brand';
const {assetDirecory} = require('utils/brand');

const FrontTablet = ({t, onClick}) => (
  <div className="text-secondary">
    <div className="bg-mobile-cover text-center" alt="mobile cover">
      <img
        src={require(`assets/images/${assetDirecory}/customer-responsive.jpg`)}
        alt="cosmote appointment"
      />
    </div>
    <div className="mobile-bottom text-center position-relative bg-white pt-2">
      <div className="main-title fs-30 font-weight-bold mt-4">
        {t(`MAIN_LABEL_${brandUpperCase}_RESIDENTIAL`)}
      </div>
      <div className="my-3 px-1x px-md-5">
        {t(`MAIN_DESCRIPTION_${brandUpperCase}_RESIDENTIAL`)}
      </div>
      <Button
        variant="primary"
        className="rounded-pill btn-cover px-4 mt-2 font-weight-semibold"
        onClick={() => onClick({display: false, btnClicked: true})}
      >
        {t(`BTN_LABEL_COMPLETED_FORM`)}
      </Button>
    </div>
  </div>
);

FrontTablet.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(FrontTablet);
