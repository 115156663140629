import React, {Fragment, memo} from 'react';
import Card from 'react-bootstrap/Card';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {toggleService, setCustomerType} from 'context/customer/actions';
import Synopsis from '../Synopsis';
import CustomerRadioTypes from './CustomerRadioTypes';
import ServiceSector from './ServiceSector';
import ServiceSectorSynopsis from '../Synopsis/ServiceSectorSynopsis';

const {assetDirecory} = require('utils/brand');

const ServiceCard = () => {
  const {t} = useTranslation();
  const {serviceSector, orderId} = useCustomerState();
  const {editable, selected, sectorValue, servicesValue} = serviceSector;
  const dispatch = useCustomerDispatch();
  const disabled = Boolean(orderId);

  const onChangeServiceSector = (sectorValue) => setCustomerType({dispatch}, {sectorValue});

  return (
    <Fragment>
      {selected ? (
        <Card className="synopsis-card rounded-meniscus mb-2">
          {disabled && (
            <div className="overlay disabled rounded-meniscus position-absolute w-100 h-100" />
          )}
          <Card.Body className="p-2 p-md-3">
            <Synopsis
              title={
                <CustomerRadioTypes
                  sectorValue={sectorValue}
                  onChangeServiceSector={onChangeServiceSector}
                  synopsis
                  t={t}
                />
              }
              selected
              onToggleSector={() => toggleService({dispatch})}
            >
              <ServiceSectorSynopsis
                servicesValue={servicesValue[0].services}
                orderId={orderId}
                notExpanded
                onChangeServiceSector={onChangeServiceSector}
                onClick={() => toggleService({dispatch})}
                t={t}
              />
            </Synopsis>
          </Card.Body>
        </Card>
      ) : (
        <div className="rounded-meniscus btn-sector fs-18 fs-md-22 text-secondary w-100 mb-2 px-2 px-md-3 py-2 d-flex justify-content-between align-items-center">
          <CustomerRadioTypes
            sectorValue={sectorValue}
            onChangeServiceSector={onChangeServiceSector}
            t={t}
          />
          <img
            src={require(`assets/images/${assetDirecory}/icons/arrow-down.svg`)}
            className="wi-16 wi-md-25"
            alt="select sector"
          />
        </div>
      )}
      {editable && (
        <Card className="expanded rounded-meniscus position-absolute shadow w-100">
          <Card.Body className="py-3 px-2 px-md-4">
            <ServiceSector />
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};

export default memo(ServiceCard);
