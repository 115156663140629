import React, {useState, useRef, useEffect, useLayoutEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import classnames from 'classnames';
import isEmpty from 'lodash.isempty';

import Loader from 'components/common/Loader';
import useForm from 'hooks/useForm';
import loginSchema from 'validations/loginSchema';
import {useAuth} from 'context/agent/auth-context';
import * as agent from 'api/agent';
import {errors as apiErrors} from 'utils/error';

function LoginForm({isMobile, t, onResetPress}) {
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const mounted = useRef(false);
  const ref = useRef(null);
  const errorHealthStatus = t('ADMIN:ERROR_HEALTH_STATUS');

  useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    const fetchHealthStatus = async () => {
      try {
        await agent.getHealthStatus();
      } catch {
        setLoginError(errorHealthStatus);
      }
    };

    fetchHealthStatus();
  }, [errorHealthStatus]);

  const auth = useAuth();

  const cardClasses = classnames('bg-white', {
    'p-20': isMobile,
    'p-30': !isMobile
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    valid,
    touched,
    setErrors,
    errors
  } = useForm({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: mounted.current && submitForm,
    validationSchema: mounted.current && loginSchema
  });

  const loaderIconDimension = isMobile ? 40 : 80;

  async function submitForm() {
    const {username, password} = values;
    setIsLoading(true);
    setErrors({});
    try {
      const data = await agent.authenticate({username, password});
      setIsLoading(false);
      setTimeout(() => {
        auth.setAuthState(data);
      }, 100);
    } catch (error) {
      setIsLoading(false);
      // Uncaught errors from BE we handle them as generic error
      if (isEmpty({...error})) {
        return setLoginError(t('ERROR_GENERIC'));
      }

      if (
        error?.code === 600 ||
        (error?.response?.status && [401, 403].includes(error.response.status))
      ) {
        return setLoginError(t('ERROR_INVALID_CREDENTIALS'));
      }

      if (error?.code) {
        return setLoginError(t(`ERRORS:${apiErrors[error.code] ?? 'GENERAL_ERROR'}`));
      }

      setLoginError(t('ERROR_GENERIC'));
    }
  }
  return (
    <>
      <Form className="login-form" onSubmit={handleSubmit}>
        <Card ref={ref}>
          {isLoading && (
            <Loader
              wrapperClasses="my-5 fs-20 fs-md-26"
              width={loaderIconDimension}
              height={loaderIconDimension}
            />
          )}
          {!isLoading && (
            <>
              <Card.Body className={cardClasses}>
                <h3 className="fs-18 font-weight-bold">{t('LOGIN')}</h3>
                {loginError && <div className="login-form-error fs-16 mt-5">{loginError}</div>}
                <Form.Group controlId="formBasicEmail" className="mt-5">
                  <Form.Label className="mb-3">{t('USERNAME')}</Form.Label>
                  <Form.Control
                    name="username"
                    type="text"
                    placeholder={t('LOGIN_PLACEHOLDER')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    isInvalid={!!errors.username && touched.username}
                  />

                  {touched.username && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.username)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Label key="password" className="mb-3">
                      {t('PASSWORD')}
                    </Form.Label>
                    <Form.Label
                      key="reset-password"
                      className="mb-3 cursor-pointer"
                      onClick={onResetPress}
                    >
                      <span className="text-primary">{t('RESET_PASSWORD')}</span>
                    </Form.Label>
                  </div>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder={t('LOGIN_PLACEHOLDER')}
                    onChange={handleChange}
                    value={values.password}
                    isInvalid={!!errors.password && touched.password}
                  />
                  {touched.password && (
                    <Form.Control.Feedback type="invalid">
                      {t(errors.password)}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Card.Body>
              <Card.Footer className={cardClasses}>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-3d w-48 fs-14 float-right"
                  disabled={!valid}
                >
                  {t('LOGIN')}
                </Button>
              </Card.Footer>
            </>
          )}
        </Card>
      </Form>
    </>
  );
}

export default LoginForm;
