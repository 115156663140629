import createReducer from 'utils/createReducer';
import {getAppointmentType} from 'components/Customer/Sector/ServiceSector/helper';
import {
  CUSTOMER_INITIALIZE,
  TOGGLE_SERVICE,
  SET_CUSTOMER_TYPE,
  SET_SERVICE,
  TOGGLE_STORE,
  SET_STORE,
  TOGGLE_DATETIME,
  SET_DATETIME,
  TOGGLE_CUSTOMERINFO,
  SET_CUSTOMERINFO,
  POPULATE_APPOINTMENT,
  SUCCESS_APPOINTMENT,
  SHOW_OTP,
  SUCCESS_OTP
} from './constants/ActionTypes';

const resetDatetime = (reset = false) =>
  reset
    ? {
        editable: false,
        selected: false,
        dateValue: null,
        timeValue: null
      }
    : {};

const initialState = {
  otp: {show: false, success: false},
  appointmentSuccess: false,
  appointmentMode: 'new',
  appointmentType: '',
  appointmentId: null,
  purchaseId: null,
  xUpdateToken: null,
  orderId: null,
  hashed: null,
  serviceSector: {
    editable: false,
    selected: false,
    sectorValue: {},
    servicesValue: [{description: '', services: []}],
    annotationValue: '',
    orderId: ''
  },
  store: {
    editable: false,
    selected: false,
    value: {},
    options: []
  },
  datetime: {
    ...resetDatetime(true)
  },
  customerInfo: {
    editable: false,
    selected: false,
    firstnameValue: '',
    lastnameValue: '',
    mobileValue: '',
    emailValue: '',
    referencePhoneNumberValue: ''
  }
};

const reducer = createReducer(initialState, {
  [CUSTOMER_INITIALIZE]: () => initialState,
  [TOGGLE_SERVICE]: (state) => ({
    ...state,
    serviceSector: {
      ...state.serviceSector,
      editable: !state.serviceSector.editable
    }
  }),
  [SET_CUSTOMER_TYPE]: (state, payload) => ({
    ...state,
    serviceSector: {
      ...state.serviceSector,
      editable: true,
      ...payload
    }
  }),
  [SET_SERVICE]: (state, payload) => {
    const appointmentType = getAppointmentType(payload);
    const appointmentTypeIsChanged = state.appointmentType !== appointmentType;

    return {
      ...state,
      appointmentType,
      serviceSector: {
        ...state.serviceSector,
        ...payload
      },
      datetime: {
        ...state.datetime,
        ...resetDatetime(appointmentTypeIsChanged)
      }
    };
  },
  [TOGGLE_STORE]: (state) => ({
    ...state,
    store: {
      ...state.store,
      editable: !state.store.editable
    }
  }),
  [SET_STORE]: (state, payload) => ({
    ...state,
    store: {
      ...state.store,
      ...payload
    },
    datetime: {
      ...resetDatetime(true)
    }
  }),
  [TOGGLE_DATETIME]: (state) => ({
    ...state,
    datetime: {
      ...state.datetime,
      editable: !state.datetime.editable
    }
  }),
  [SET_DATETIME]: (state, payload) => ({
    ...state,
    datetime: {
      ...state.datetime,
      ...payload
    }
  }),
  [TOGGLE_CUSTOMERINFO]: (state) => ({
    ...state,
    customerInfo: {
      ...state.customerInfo,
      editable: !state.customerInfo.editable
    }
  }),
  [SET_CUSTOMERINFO]: (state, payload) => ({
    ...state,
    customerInfo: {
      ...state.customerInfo,
      ...payload
    }
  }),
  [POPULATE_APPOINTMENT]: (state, payload) => ({
    ...state,
    appointmentType: payload.appointmentType ?? state.appointmentType,
    orderId: payload.orderId ?? state.orderId,
    hashed: payload.hashed ?? state.hashed,
    serviceSector: {
      ...state.serviceSector,
      ...payload.serviceSector
    },
    store: {
      ...state.store,
      ...payload.store
    },
    datetime: {
      ...state.datetime,
      ...payload.datetime
    },
    customerInfo: {
      ...state.customerInfo,
      ...payload.customerInfo
    }
  }),
  [SUCCESS_APPOINTMENT]: (state) => ({
    ...state,
    appointmentSuccess: true
  }),
  [SHOW_OTP]: (state, payload) => ({
    ...state,
    otp: {
      ...state.otp,
      show: true
    },
    appointmentMode: payload?.appointmentMode,
    appointmentId: payload?.appointmentId,
    purchaseId: payload?.purchaseId
  }),
  [SUCCESS_OTP]: (state, payload) => ({
    ...state,
    otp: {
      ...state.otp,
      success: true,
      show: false
    },
    xUpdateToken: payload.xUpdateToken
  })
});

export {reducer, initialState};
