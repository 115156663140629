import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import {customerTypes} from 'components/Customer/Sector/ServiceSector/helper';

const CustomerRadioTypes = ({sectorValue, synopsis, onChangeServiceSector, t}) => {
  const checkboxClasses = classnames('form-element mr-4', {
    'font-weight-semibold': synopsis
  });

  return (
    <div>
      {customerTypes(t).map(({title, code}) => (
        <Form.Check
          key={code}
          id={`radio-${code}`}
          className={checkboxClasses}
          inline
          value={code}
          checked={sectorValue.code === code}
          name="radio-service-sector"
          type="radio"
          label={title}
          onChange={() => onChangeServiceSector({title, code})}
          onClick={() => onChangeServiceSector({title, code})}
        />
      ))}
    </div>
  );
};

CustomerRadioTypes.defaultProps = {
  synopsis: false
};

CustomerRadioTypes.propTypes = {
  sectorValue: PropTypes.object.isRequired,
  synopsis: PropTypes.bool.isRequired,
  onChangeServiceSector: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(CustomerRadioTypes);
