import {useState, useEffect, useCallback} from 'react';
import * as yup from 'yup';

/**
 * Form hook that handles field updates, performs submission and error reporting
 *
 * @param {Object}, initialValues
 * @param {Function}, onSubmit
 * @param {Function}, onValidate,
 * @param {Object}, validationSchema - Yup.js schema
 *
 * @returns {Object}
 */
function useForm({initialValues, onSubmit, validationSchema, onValidate}) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(() =>
    Object.keys(initialValues).reduce(
      (prev, next) => ({
        ...prev,
        [next]: false
      }),
      {}
    )
  );

  function getValue(type, target) {
    if (type === 'checkbox') {
      return target.checked;
    }
    return target.value;
  }

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit();
  }

  function handleChange(event) {
    event.persist();
    const {target} = event;
    const {name, type} = target;

    if (validationSchema) {
      validateField(name, getValue(type, target));
      setValid(formIsValid());
    }

    setValues((values) => ({
      ...values,
      [event.target.name]: getValue(type, target)
    }));

    if (!touched[name]) {
      setFieldTouched(name);
    }
  }

  function handleBlur(event) {
    event.persist();
    const {target} = event;
    const {name, value} = target;

    validateField(name, value);
    setFieldTouched(name);
  }

  function validateField(name, value) {
    // If no validation Schema is present, or field is non included in schema do nothing
    if (!validationSchema || !validationSchema.fields[name]) return;

    yup
      .reach(validationSchema, name)
      .validate(value)
      .then(() => {
        // eslint-disable-next-line no-unused-vars
        setErrors(({[name]: value, ...restErrors}) => ({...restErrors}));
      })
      .catch((e) => {
        setErrors((prevErrors) => ({...prevErrors, [name]: e.message}));
      });
  }

  function setFieldValue(name, value) {
    if (validationSchema) {
      validateField(name, value);
      setValid(formIsValid());
    }

    setValues((values) => ({
      ...values,
      [name]: value
    }));
  }

  function setFieldTouched(name) {
    setTouched((touched) => ({
      ...touched,
      [name]: true
    }));
  }

  function setFieldError(name, errorMessage) {
    setErrors((prevErrors) => ({...prevErrors, [name]: errorMessage}));
  }

  /**
   * This function is used to check if we have at least one error reported
   */
  const formIsValid = useCallback(() => Object.keys(errors).length === 0, [errors]);

  useEffect(() => {
    setValid(formIsValid());
  }, [errors, formIsValid]);

  /**
   * Side effects for custom validation,
   * custom validator must return an object
   */
  useEffect(() => {
    if (onValidate) {
      const errors = onValidate(values);

      return setErrors(errors);
    }
  }, [errors, onValidate, values]);

  // We break the deps rule here to validate form also initially, which is not great
  useEffect(() => {
    Object.keys(initialValues).forEach((name) => validateField(name, initialValues[name]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    valid,
    values,
    errors,
    touched,
    setErrors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    validateField
  };
}

export default useForm;
