import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useCustomerState} from 'context/customer/customer-context';
import {useCustomerDispatch} from 'context/customer/customer-context';
import {successOTP, populateAppointment, successAppointment} from 'context/customer/actions';
import {
  confirmAppointmentWithOtp,
  confirmPurchaseWithOtp,
  fetchAppointmentDetailsOtp,
  generateAppointmentOTP,
  generatePurchaseOTP
} from 'api/customer';
import {updateAppointmentDetails} from 'components/Customer/SectorsForm/helper';
import useWindowSize from 'hooks/useWindowSize';
import {isMobile} from 'utils/size';
import {errors} from 'utils/error';

const initOTP = {value: '', error: ''};

const useOTPMechanism = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState({...initOTP});
  const [reset, setReset] = useState(false);
  const {width} = useWindowSize();
  const iconDimension = isMobile(width) ? 40 : 80;
  const {
    appointmentId,
    appointmentMode,
    purchaseId,
    customerInfo: {mobileValue: mobileNumber}
  } = useCustomerState();
  const {
    t,
    i18n: {language: lang}
  } = useTranslation();
  const dispatch = useCustomerDispatch();

  const onChangeOTP = (value) => {
    setOtp({value, error: ''});
  };

  const onSendNewOtp = async () => {
    try {
      setLoading(true);
      setError(false);
      if (purchaseId) {
        await generatePurchaseOTP({purchaseId, lang});
      } else {
        await generateAppointmentOTP({appointmentId, lang});
      }

      setOtp({...initOTP});
      setReset((reset) => !reset);
    } catch (error) {
      const code = error?.code;
      if ([317, 318].includes(code)) {
        setOtp(() => ({value: '', error: t(`ERRORS:${errors[code]}`)}));
      } else {
        setError(code ?? true);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitBtn = async () => {
    try {
      setLoading(true);
      setError(false);
      if (purchaseId) {
        await confirmPurchaseWithOtp({
          postData: {token: otp.value, purchaseId},
          lang
        });
        successOTP({dispatch}, {xUpdateToken: null});
        successAppointment({dispatch});
      } else {
        if (appointmentMode === 'new') {
          await confirmAppointmentWithOtp({
            postData: {token: otp.value, appointmentId},
            lang
          });
          successOTP({dispatch}, {xUpdateToken: null});
          successAppointment({dispatch});
        } else if (appointmentMode === 'edit') {
          const response = await fetchAppointmentDetailsOtp({
            postData: {token: otp.value, appointmentId},
            lang
          });
          successOTP({dispatch}, {xUpdateToken: response.headers['x-update-token']});
          populateAppointment({dispatch}, updateAppointmentDetails(response.data, t));
        }
      }
    } catch (error) {
      const code = error?.code;
      if ([313, 314, 316].includes(code)) {
        setOtp(() => ({value: '', error: t(`ERRORS:${errors[code]}`)}));
      } else {
        setError(code ?? true);
      }
      setLoading(false);
    }
  };

  return [
    mobileNumber,
    loading,
    error,
    otp,
    reset,
    iconDimension,
    appointmentMode === 'new',
    onChangeOTP,
    onSendNewOtp,
    onSubmitBtn,
    t
  ];
};

export default useOTPMechanism;
