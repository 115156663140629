const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {}

  document.body.removeChild(textArea);
};

const copyTextToClipboard = (ref) => {
  if (!ref || !ref.current) {
    return;
  }

  const text = ref.current.innerText;

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);

    return;
  }

  navigator.clipboard.writeText(text);
};

const asteriskText = (text) => {
  const textLength = text.length;
  return `${text.substring(0, 1)}${'*'.repeat(textLength - 4)}${text.substring(
    textLength - 3,
    textLength
  )}`;
};

export {copyTextToClipboard, asteriskText};
