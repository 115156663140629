import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Lottie from 'react-lottie';
import loader from './loader';
import {isCosmote} from 'utils/brand';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader(
    isCosmote
      ? [0.313725490196, 0.619607843137, 0.18431372549, 1]
      : [0, 0.262745098039, 0.513725490196, 1]
  )
};

const Loader = ({wrapperClasses, width, height}) => {
  const {t} = useTranslation();

  return (
    <div className={`text-center m-3 ${wrapperClasses}`}>
      <div className="mb-4">
        <Lottie options={defaultOptions} width={width} height={height} />
      </div>
      <div className="font-weight-semibold">{t('PLEASE_WAIT')}</div>
    </div>
  );
};

Loader.defaultProps = {
  width: 48,
  height: 48
};

Loader.propTypes = {
  wrapperClasses: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default Loader;
