const purchaseStatus = {
  COMPLETED: 'COMPLETED', // all completed
  PENDING: 'PENDING',
  PENDING_ALL: 'PENDING_ALL', // pending & out of stock
  OUT_OF_STOCK: 'OUT_OF_STOCK', // αναμονή παραγγελίας
  BOOKED_APPOINTMENT: 'BOOKED_APPOINTMENT',
  NOT_INTERESTED: 'NOT_INTERESTED',
  NOT_RESPONDED: 'NOT_RESPONDED',
  APPOINTMENT_CANCELED: 'APPOINTMENT_CANCELED'
};

export {purchaseStatus};
