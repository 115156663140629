import md5 from 'md5';

export const md5ServiceList = (list) => {
  return list.map((item) => {
    return {
      group: item.group,
      services: item.services.map((s) => ({
        ...s,
        uuid: md5(item.group + s.description),
        checkForClearance: ['επιχείρηση', 'business'].includes(item.group.toLowerCase())
      }))
    };
  });
};

export const md5AppointmentServices = (list, type = '') => {
  try {
    return list.map((s) => ({
      ...s,
      uuid: md5(s.group + s.description),
      checkForClearance: ['επιχείρηση', 'business'].includes(s.group.toLowerCase()),
      customerType: type.toLowerCase()
    }));
  } catch (error) {
    // fail silently
  }
};
