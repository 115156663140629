const errors = {
  1: 'GENERAL_ERROR',
  100: 'GENERAL_DATA_ERROR',
  101: 'GENERAL_DATA_INTEGRITY_ERROR',
  102: 'GENERAL_DATA_NOT_FOUND_ERROR',
  103: 'APPOINTMENT_NOT_FOUND',
  104: 'STORE_NOT_FOUND',
  105: 'CUSTOMER_TYPE_NOT_FOUND',
  112: 'INSPECTOR_NOT_FOUND',
  108: 'PURCHASE_NOT_FOUND',
  200: 'GENERAL_CONFIGURATION_ERROR',
  300: 'GENERAL_VALIDATION_ERROR',
  301: 'APPOINTMENT_RESTRICTION_ALREADY_TWO',
  302: 'APPOINTMENT_RESTRICTION_SAME_STORE',
  303: 'APPOINTMENT_ALREADY_STOPPED',
  304: 'APPOINTMENT_ALREADY_STARTED',
  305: 'APPOINTMENT_ALREADY_CONFIRMED',
  306: 'APPOINTMENT_CANCELED',
  309: 'APPOINTMENT_RESTRICTION_DIFFERENT_DATE',
  310: 'APPOINTMENT_RESTRICTION_UNAVAILABLE_TIME',
  311: 'APPOINTMENT_DATE_UNAVAILABLE',
  312: 'APPOINTMENT_SERVICE_INVALID',
  313: 'OTP_INCORRECT',
  314: 'OTP_EXPIRED',
  316: 'APPOINTMENT_OTP_RETRIES_RESTRICTION',
  317: 'APPOINTMENT_OTP_REGENERATION_TIME_RESTRICTION',
  318: 'APPOINTMENT_OTP_MAX_REGENERATION_TIMES',
  322: 'STORE_NAME_EXISTS',
  324: 'PURCHASE_RESTRICTION_ALREADY_TEN',
  325: 'PURCHASE_RESTRICTION_ALREADY_TWO_IN_STORE',
  329: 'EMAIL_EXISTS',
  330: 'INVALID_MOBILE_PHONE',
  331: 'NO_RESET_PASSWORD',
  332: 'DIGITAL_CARE_RESTRICTION_PER_DAY',
  333: 'AGENT_NAME_MANDATORY',
  335: 'PASSWORD_ALREADY_USED',
  400: 'GENERAL_COMMUNICATION_ERROR',
  401: 'EMAIL_INFORMATIVE_ERROR',
  402: 'EMAIL_CONFIRMATION_ERROR',
  403: 'EMAIL_CANCELLATION_ERROR',
  500: 'GENERAL_IO_ERROR',
  600: 'GENERAL_SECURITY_ERROR',
  601: 'ACCOUNT_LOCKED',
  602: 'CREDENTIALS_EXPIRED',
  603: 'USER_WAITING_PERIOD_ERROR'
};

export {errors};
