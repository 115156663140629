import React, {Fragment, memo} from 'react';
import Form from 'react-bootstrap/Form';
import Synopsis from '../Synopsis';
import StoreSynopsis from '../Synopsis/StoreSynopsis';
import NextStep from '../../common/NextStep';
import ErrorMessage from 'components/common/ErrorMessage';
import Loader from 'components/common/Loader';
import useStore from 'hooks/useStore';

const Store = () => {
  const {
    editable,
    setSearchText,
    autoCompleteRef,
    storeCompleted,
    toggleSector,
    selectedStore,
    onFindNearestStore,
    searchText,
    isLoading,
    error,
    searchStores,
    onChangeSearchStore,
    closeSector,
    t
  } = useStore();

  return (
    <Fragment>
      <Synopsis
        title={t('STORE')}
        selected={storeCompleted}
        onToggleSector={toggleSector}
        expanded={editable}
      >
        <StoreSynopsis value={selectedStore} t={t} />
      </Synopsis>

      <div className="fs-14 d-md-flex justify-content-between">
        <span>{t('SEARCH_ADDRESS_POSTAL_CODE')}</span>
        <button
          type="button"
          className="link-button ml-3 text-left d-none d-md-block mb-3 fs-14"
          onClick={onFindNearestStore}
        >
          {t('NEAREST_STORE')}
        </button>
      </div>

      <div className="d-flex border my-3">
        <Form.Control
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          className="rounded-10 border-0"
          type="input"
          placeholder={t('SEARCH_ADRRESS_PLACEHOLDER')}
          ref={autoCompleteRef}
        />
        <div className="fs-14 border-left my-2 px-3 text-center">
          <i className="fa fa-search text-secondary" />
        </div>
      </div>
      <button
        type="button"
        className="link-button ml-1 d-md-none mb-3 fs-14"
        onClick={onFindNearestStore}
      >
        {t('NEAREST_STORE')}
      </button>
      {isLoading && <Loader />}
      {error && <ErrorMessage />}
      {!error && !isLoading && (
        <div className="search-stores">
          {searchStores.fetched && searchStores.options.length === 0 && (
            <div className="text-center">{t('NO_STORES_FOUND')}</div>
          )}
          {searchStores.options.map(({code, label, title, icon, brandClass}) => (
            <Form.Check
              key={code}
              id={`store-${code}`}
              className="form-element"
              value={code}
              checked={selectedStore.code === code}
              name="radio-search-store"
              type="radio"
              label={
                <div className="d-flex align-items-center">
                  <img src={icon} className="align-self-start mt-1" width="16" alt="brand" />
                  <span>
                    <span className={`mx-2 font-weight-semibold ${brandClass}`}>{label}</span>
                    {title}
                  </span>
                </div>
              }
              onChange={onChangeSearchStore}
            />
          ))}
        </div>
      )}
      <NextStep display={Boolean(selectedStore.code)} onClick={closeSector} t={t} />
    </Fragment>
  );
};

export default memo(Store);
