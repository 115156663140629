import translationEN from './en/translation';
import translationEL from './el/translation';
import adminEN from './en/admin';
import adminEL from './el/admin';
import errorsEN from './en/errors';
import errorsEL from './el/errors';
import validationEN from './en/validation';
import validationEL from './el/validation';
import reportsEN from './en/reports';
import reportsEL from './el/reports';

export default {
  en: {
    translation: translationEN,
    ADMIN: adminEN,
    ERRORS: errorsEN,
    VALIDATION: validationEN,
    REPORTS: reportsEN
  },
  el: {
    translation: translationEL,
    ADMIN: adminEL,
    ERRORS: errorsEL,
    VALIDATION: validationEL,
    REPORTS: reportsEL
  }
};
