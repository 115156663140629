import React, {Fragment, memo} from 'react';
import {useTranslation} from 'react-i18next';
import Header from '../Header';
import Section from '../Section';
import LeftSide from '../Section/LeftSide';
import CardCancellation from './CardCancellation';
import {isMobile, isTablet} from 'utils/size';
import useWindowSize from 'hooks/useWindowSize';
import {SECTORS_DETAILS} from 'utils/brand';

const AppointmentCancellation = () => {
  const {t, i18n} = useTranslation();
  const {width} = useWindowSize();
  const {language} = i18n;

  return (
    <Fragment>
      <Header i18n={i18n} />
      <Section
        isTablet={isTablet(width)}
        leftSide={<LeftSide text={SECTORS_DETAILS.residential.text} t={t} />}
        language={language}
        image={SECTORS_DETAILS.residential.image}
        t={t}
      >
        <CardCancellation isMobile={isMobile(width)} language={language} t={t} />
      </Section>
    </Fragment>
  );
};

export default memo(AppointmentCancellation);
