import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';

const {assetDirecory} = require('utils/brand');

const Success = ({title, desc, children}) => (
  <Fragment>
    <img
      src={require(`assets/images/${assetDirecory}/icons/accomplished.svg`)}
      className="mb-4 wi-48 wi-md-80"
      alt="appointment accomplished"
    />
    <div className="fs-20 fs-md-26 px-0 px-md-3">
      <div className="font-weight-semibold mb-3">{title}</div>
      <div>{desc}</div>
      {children}
    </div>
  </Fragment>
);

Success.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default memo(Success);
