import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

const NextStep = ({display, onClick, t}) => (
  <div className="clearfix">
    {display && (
      <Button
        variant="primary"
        className="rounded-pill btn-cover px-4 mt-2 font-weight-semibold float-right"
        onClick={onClick}
      >
        {t('SECTOR_CARD_NEXT')}
      </Button>
    )}
  </div>
);

NextStep.defaultProps = {
  display: false
};

NextStep.propTypes = {
  display: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(NextStep);
