import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GradientGrid from 'components/common/GradientGrid';
import {brandParam, brandUpperCase} from 'utils/brand';

const {assetDirecory} = require('utils/brand');

const Section = ({leftSide, language, t, children, isTablet, image}) => (
  <section className="main-section">
    <div className="bg-screen bg-cover"></div>
    <Container className="h-100">
      <Row className="h-100 ">
        <Col xs={isTablet ? 12 : 6} className="text-white d-lg-block main-row my-5">
          {!isTablet && leftSide}
          {children}
        </Col>
        <GradientGrid
          image={!isTablet ? require(`assets/images/${assetDirecory}/${image}.png`) : null}
          xs={7}
        />
      </Row>
    </Container>
    <div className="footer-gdpr py-3 text-center text-md-left">
      <Container>
        <a
          href={`/email-static/Data_Privacy_Notice_Portal_${brandUpperCase}_${language}.pdf`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white"
        >
          {t('GDPR_LINK', {brand: brandParam})}
        </a>
      </Container>
    </div>
  </section>
);

Section.propTypes = {
  leftSide: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default memo(Section);
