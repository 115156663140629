import React, {Fragment, memo} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Synopsis from '../Synopsis';
import CustomerInfoSynopsis from '../Synopsis/CustomerInfoSynopsis';
import NextStep from '../../common/NextStep';
import useCustomerInfo from 'hooks/useCustomerInfo';
import {brandParam, brandUpperCase} from 'utils/brand';

const CustomerInfo = () => {
  const {
    editable,
    language,
    atLeastOneCompleted,
    closeSector,
    fields,
    firstname,
    lastname,
    email,
    mobile,
    referencePhoneNumber,
    terms,
    formIsValid,
    onChangeTerms,
    toggleSector,
    onChange,
    onBlur,
    t
  } = useCustomerInfo();

  return (
    <Fragment>
      <Synopsis
        title={t('CUSTOMER_INFORMATION')}
        selected={atLeastOneCompleted}
        onToggleSector={toggleSector}
        expanded={editable}
      >
        <CustomerInfoSynopsis
          firstnameValue={firstname.value}
          lastnameValue={lastname.value}
          mobileValue={mobile.value}
          emailValue={email.value}
          referencePhoneNumberValue={referencePhoneNumber.value}
          t={t}
        />
      </Synopsis>
      <Form.Row className="form-element mb-2">
        {fields.map(({id, value, label, type, mandatory, error, setState, md, touched}) => (
          <Form.Group key={id} as={Col} xs={12} md={md} controlId={id} className="mb-1 mb-md-2">
            <Form.Label className="mb-0">
              <span className="text-primary fs-20">{mandatory && '*'}</span>
              {label}
            </Form.Label>
            <Form.Control
              type={type}
              value={value}
              onChange={({target}) => onChange(target, setState)}
              onBlur={({target}) => onBlur(target, setState)}
              isInvalid={!!(error && touched)}
            />
            {error && touched && (
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            )}
          </Form.Group>
        ))}
        <Form.Group as={Col} xs={12} className="mb-1 mb-md-2 mx-2 mx-md-4">
          <Form.Check
            id="checkbox-terms"
            className="my-3 px-5 px-md-0"
            label={
              <span>
                {t('READ_AND_AGREE_GDPR')}{' '}
                <a
                  href={`/email-static/Data_Privacy_Notice_Portal_${brandUpperCase}_${language}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('GDPR_LINK', {brand: brandParam})}
                </a>
              </span>
            }
            value="terms"
            type="checkbox"
            checked={terms}
            onChange={onChangeTerms}
          />
        </Form.Group>
      </Form.Row>
      <NextStep display={formIsValid} onClick={closeSector} t={t} />
    </Fragment>
  );
};

export default memo(CustomerInfo);
