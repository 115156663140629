import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'components/common/ErrorMessage';
import Loader from 'components/common/Loader';
import CardFlowWrapper from '../CardFlowWrapper';
import ServiceCard from '../Sector/ServiceCard';
import StoreCard from '../Sector/StoreCard';
import DatetimeCard from '../Sector/DatetimeCard';
import CustomerInfoCard from '../Sector/CustomerInfoCard';
import AppointmentConfirmed from '../AppointmentConfirmed';
import Otp from '../Otp';
import Footer from './Footer';
import PopulateFooter from './PopulateFooter';

import useSectorForm from 'hooks/useSectorForm';

const SectorsForm = ({isMobile, language, t}) => {
  const {
    appointmentSuccess,
    appointmentMode,
    isPurchase,
    btnEnabled,
    editable,
    otp,
    isLoading,
    error,
    token,
    onSubmitBtn,
    handleBackOnError
  } = useSectorForm(language, t);

  const titleTr = t('VISIT_DETAILS');

  if (isLoading) {
    const loaderIconDimension = isMobile ? 40 : 80;

    return (
      <CardFlowWrapper bodyClasses="py-5 px-3 text-center">
        <Loader
          wrapperClasses="my-5 fs-20 fs-md-26"
          width={loaderIconDimension}
          height={loaderIconDimension}
        />
      </CardFlowWrapper>
    );
  }

  if (error) {
    const errorIconDimension = isMobile ? 40 : 80;

    return (
      <CardFlowWrapper bodyClasses="py-5 px-3 text-center">
        <ErrorMessage
          wrapperClasses="my-5 fs-20 fs-md-26"
          width={errorIconDimension}
          code={error}
        />
        <div className="mt-3 cursor-pointer text-secondary" onClick={handleBackOnError}>
          {t('BACK_VISIT_DETAILS')}
        </div>
      </CardFlowWrapper>
    );
  }

  if (otp.show) {
    return <Otp />;
  }

  if (appointmentSuccess) {
    return <AppointmentConfirmed />;
  }

  return (
    <Fragment>
      <div className="fs-22 text-white text-center d-md-none font-weight-bold mb-2">{titleTr}</div>
      <CardFlowWrapper bodyClasses="p-3">
        {editable && <div className="overlay rounded-meniscus position-absolute w-100 h-100" />}
        <div className="fs-18 text-center text-primary d-none d-md-block mb-3">{titleTr}</div>
        <div className="w-100 position-relative">
          <ServiceCard />
          <StoreCard />
          {!isPurchase && <DatetimeCard />}
          <CustomerInfoCard />
        </div>
        {appointmentMode === 'edit' ? (
          <PopulateFooter token={token} onSubmitBtn={onSubmitBtn} />
        ) : (
          <Footer btnEnabled={btnEnabled} isPurchase={isPurchase} onSubmitBtn={onSubmitBtn} />
        )}
      </CardFlowWrapper>
    </Fragment>
  );
};

SectorsForm.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(SectorsForm);
