import React, {Fragment, memo} from 'react';
import Button from 'react-bootstrap/Button';
import CardFlowWrapper from '../CardFlowWrapper';
import OTPInput from 'components/common/OTPInput';
import ErrorMessage from 'components/common/ErrorMessage';
import Loader from 'components/common/Loader';
import useOTPMechanism from 'hooks/useOTPMechanism';
import {asteriskText} from 'utils/text';

const OTP_DIGITS = 6;
const {assetDirecory} = require('utils/brand');

const Otp = () => {
  const [
    mobileNumber,
    loading,
    error,
    otp,
    reset,
    iconDimension,
    isNewAppointment,
    onChangeOTP,
    onSendNewOtp,
    onSubmitBtn,
    t
  ] = useOTPMechanism();

  return (
    <Fragment>
      <CardFlowWrapper bodyClasses="py-3 px-3 px-md-4 text-center">
        {loading && (
          <Loader
            wrapperClasses="my-5 fs-20 fs-md-26"
            width={iconDimension}
            height={iconDimension}
          />
        )}
        {error && (
          <ErrorMessage wrapperClasses="my-5 fs-20 fs-md-26" width={iconDimension} code={error} />
        )}
        {!(loading || error) && (
          <Fragment>
            <div className="mt-3 fs-20 fs-md-26">
              <img
                src={require(`assets/images/${assetDirecory}/icons/sms.svg`)}
                width={iconDimension}
                className="mb-4 wi-48 wi-md-80"
                alt="sms"
              />
              <div className="font-weight-semibold">
                {t(`APPOINTMENT_${isNewAppointment ? 'REGISTRATION' : 'RETRIEVAL'}_ALMOST_READY`)}
              </div>
              {t('ADD_OTP_MOBILE', {
                digits: OTP_DIGITS,
                mobileNumber: mobileNumber ? asteriskText(mobileNumber) : ''
              })}
            </div>
            <div className="my-4 d-flex justify-content-center">
              <div className="otpWrapper">
                <OTPInput
                  autoFocus
                  isNumberInput
                  length={OTP_DIGITS}
                  reset={reset}
                  className={`otpContainer mb-2 ${otp.error ? 'error' : ''}`}
                  inputClassName="otpInput"
                  onChangeOTP={onChangeOTP}
                />
                {otp.error ? (
                  <div className="text-error text-center text-md-left">{otp.error}</div>
                ) : (
                  <div className="text-shade-cblue-2 text-center text-md-left">
                    {t('TIME_ACTIVE_OTP', {minutes: 5})}
                  </div>
                )}
              </div>
            </div>
            <div className="d-md-flex justify-content-between align-items-center">
              <div
                className="text-shade-cblue-2 font-weight-semibold cursor-pointer mb-3"
                onClick={onSendNewOtp}
              >
                {t('SEND_NEW_OTP')}
              </div>
              <Button
                variant="primary"
                className="rounded-pill btn-cover px-4 mt-2 font-weight-semibold mb-3"
                disabled={otp.value.length !== OTP_DIGITS}
                onClick={onSubmitBtn}
              >
                {t('VERIFICATION')}
              </Button>
            </div>
          </Fragment>
        )}
      </CardFlowWrapper>
    </Fragment>
  );
};

export default memo(Otp);
