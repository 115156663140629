const CUSTOMER_INITIALIZE = 'CUSTOMER_INITIALIZE';

const TOGGLE_SERVICE = 'TOGGLE_SERVICE';
const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
const SET_SERVICE = 'SET_SERVICE';
const TOGGLE_STORE = 'TOGGLE_STORE';
const SET_STORE = 'SET_STORE';
const TOGGLE_DATETIME = 'TOGGLE_DATETIME';
const SET_DATETIME = 'SET_DATETIME';
const TOGGLE_CUSTOMERINFO = 'TOGGLE_CUSTOMERINFO';
const SET_CUSTOMERINFO = 'SET_CUSTOMERINFO';
const POPULATE_APPOINTMENT = 'POPULATE_APPOINTMENT';
const SUCCESS_APPOINTMENT = 'SUCCESS_APPOINTMENT';

const SHOW_OTP = 'SHOW_OTP';
const SUCCESS_OTP = 'SUCCESS_OTP';

export {
  CUSTOMER_INITIALIZE,
  TOGGLE_SERVICE,
  SET_CUSTOMER_TYPE,
  SET_SERVICE,
  TOGGLE_STORE,
  SET_STORE,
  TOGGLE_DATETIME,
  SET_DATETIME,
  TOGGLE_CUSTOMERINFO,
  SET_CUSTOMERINFO,
  POPULATE_APPOINTMENT,
  SUCCESS_APPOINTMENT,
  SHOW_OTP,
  SUCCESS_OTP
};
